import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';

import { Title } from 'lib/Title';
import { Video } from 'lib/Video';

class ListingGalleryItem extends Component {
	static propTypes = {
		images: PropTypes.array,
		title: PropTypes.string,
		onImageClick: PropTypes.func,
	};

	static defaultProps = {
		images: [],
		title: '',
	};

	onImageClick = (unstrippedUrl, index) => {
		const url = queryString.parseUrl(unstrippedUrl).url;
		this.props.onImageClick(url, index);
	};

	render() {
		const { title, images, videos } = this.props;

		if (!images.length && !videos.length) return null;

		return (
			<Container>
				<TitleWrapper>
					<Title standardMargin smallMobileText>
						{title}
					</Title>
				</TitleWrapper>
				{!!images && !!images.length && (
					<ImagesContainer>
						{images.map((image, i) => (
							<ImageContainer key={image}>
								<Image src={image} onClick={() => this.onImageClick(image, i)} />
							</ImageContainer>
						))}
					</ImagesContainer>
				)}
				{!!videos && !!videos.length && (
					<VideoContainer>
						{!!videos && videos.map((video, i) => <Video videoUrl={video} key={i} />)}
					</VideoContainer>
				)}
			</Container>
		);
	}
}

export default ListingGalleryItem;

const TitleWrapper = styled.div`
	padding: 0 16px;
	flex: 1;
	max-width: 400px;
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
`;

const ImageContainer = styled.div`
	width: 50%;
	max-width: 360px;
	padding: 4px;
`;

const ImagesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 12px;
	padding: 0 4px;
	flex: 2;
	max-width: 728px;

	@media (min-width: 768px) {
		margin-bottom: 0;
	}
`;

const VideoContainer = styled.div`
	flex: 2;
	max-width: 728px;
	margin-bottom: 48px;
	padding: 0 4px;
	margin-top: 8px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;
