import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoint } from '@tiaanduplessis/react-resize';

import SlidingPane from '../../common/sliding-pane';
import FullScreenModal from '../../common/fullscreen-modal';
import theme from 'config/theme';
import { TopBar } from '../TopBar';
import { Title } from 'lib/Title';
import { Divider } from 'lib/Divider';

const { colors, topBar } = theme;

class RentalTermDetails extends Component {
	static propTypes = {
		booking_cancel_policy: PropTypes.string,
		security_deposit: PropTypes.string,
		admin_fee: PropTypes.string,
	};

	renderCancellationPolicy = () => {
		const { booking_cancel_policy } = this.props;
		if (booking_cancel_policy == null) return null;

		return (
			<>
				<PolicyItem>
					<PolicyItemTitle>Booking Cancellation Policy</PolicyItemTitle>
					<PolicyItemDescription>{booking_cancel_policy}</PolicyItemDescription>
					{
						// eslint-disable-next-line
						<PolicyItemDisclaimer>
							The booking cancellation policy is the landlord's rules around how you can cancel your
							booking to stay there, <b>before you've moved in</b>, and under what circumstances the
							deposit is returnable. Please note that DigsConnect is not involved in any aspect of
							the leasing, this is just here for your information when making a booking to a
							property.
						</PolicyItemDisclaimer>
					}
				</PolicyItem>
				<Divider />
			</>
		);
	};

	renderSecurityDeposit = () => {
		const { security_deposit } = this.props;
		if (security_deposit == null) return null;

		return (
			<>
				<PolicyItem>
					<PolicyItemTitle>Security Deposit Required </PolicyItemTitle>
					<PolicyItemDescription>
						This listing requires a deposit payable to the landlord upon successful booking{' '}
					</PolicyItemDescription>
					<DepositAmount>
						R{security_deposit}
						<DepositText>/ deposit </DepositText>
					</DepositAmount>
				</PolicyItem>
				<Divider />
			</>
		);
	};

	renderAdminFee = () => {
		const { admin_fee } = this.props;
		if (admin_fee == null) return null;

		return (
			<PolicyItem>
				<PolicyItemTitle>Admin Fee </PolicyItemTitle>
				<PolicyItemDescription>
					This listing carries a booking admin fee payable to the landlord upon successful booking
				</PolicyItemDescription>
				<DepositAmount>
					R{admin_fee}
					<DepositText>/ admin fee </DepositText>
				</DepositAmount>
			</PolicyItem>
		);
	};

	renderPolicyItems = () => {
		const cancellationPolicySection = this.renderCancellationPolicy();
		const securityDepositSection = this.renderSecurityDeposit();
		const renderAdminFeeSection = this.renderAdminFee();
		if (!cancellationPolicySection && !securityDepositSection && !renderAdminFeeSection) {
			return <PolicyItemDescription>This listing specifies no rental terms</PolicyItemDescription>;
		}
		return (
			<>
				{cancellationPolicySection}
				{securityDepositSection}
				{renderAdminFeeSection}
			</>
		);
	};

	render() {
		const mobileBreakPointPx = 1080;
		const desktopBreakPointPx = mobileBreakPointPx + 1;

		return (
			<>
				<Breakpoint maxWidth={mobileBreakPointPx}>{this.renderMobileDetails()}</Breakpoint>
				<Breakpoint minWidth={desktopBreakPointPx}>{this.renderDesktopDetails()}</Breakpoint>
			</>
		);
	}

	renderMobileDetails = () => {
		const { show, onDismiss } = this.props;

		return (
			<FullScreenModal showModal={show}>
				<TopBar onClose={onDismiss} />
				<DetailContainerMobile>
					<Title>Rental terms</Title>
					{this.renderPolicyItems()}
				</DetailContainerMobile>
			</FullScreenModal>
		);
	};

	renderDesktopDetails = () => {
		const { show, onDismiss } = this.props;

		return (
			<SlidingPane showPane={show} onRequestClose={onDismiss} size="regular">
				<TopBar closeOnRight onClose={onDismiss} />
				<DetailContainerDesktop>
					<Title>Rental terms</Title>
					{this.renderPolicyItems()}
				</DetailContainerDesktop>
			</SlidingPane>
		);
	};
}

export default RentalTermDetails;

/**styled components */

const PolicyItem = styled.div`
	margin-bottom: 32px;
`;

const PolicyItemTitle = styled.h2`
	color: #041f47;
	margin-bottom: 12px;
	font-weight: 400;
	font-size: 16px;
	font-weight: 400;
`;

const DepositAmount = styled.p`
	color: #041f47;
	font-size: 24px;
	font-weight: 400;
`;

const DepositText = styled.span`
	font-weight: 400;
	font-size: 16px;
	color: ${colors.grey60};
`;

const PolicyItemDescription = styled.p`
	font-weight: 400;
	color: ${colors.grey60};
	font-size: 14px;
`;

const PolicyItemDisclaimer = styled.small`
	color: ${colors.grey60};
	margin-top: 8px;
	line-height: 1.4;
`;

/**mobile styles */

const DetailContainerMobile = styled.div`
	overflow: auto;
	height: calc(100vh - ${topBar.height});
	margin-bottom: 30px;
	padding-top: 34px;
	padding-left: 24px;
	padding-right: 24px;
`;

/**desktop styles */

const DetailContainerDesktop = styled.div`
	overflow: auto;
	height: calc(100vh - ${topBar.height});
	margin-bottom: 30px;
	padding-top: 55px;
	padding-left: 48px;
	padding-right: 48px;
`;
