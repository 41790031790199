import React from 'react';

import styled from 'styled-components';

import { Divider } from 'lib/Divider';
import { Title } from 'lib/Title';
import { Modal } from 'lib/Modal';
import { TopBar } from '../TopBar';
import theme from 'config/theme';
import { featureHasMeta } from './featureHasMeta';

const { colors, topBar } = theme;

const addBullet = text => `• ${text}`;

const ListingFeaturesDetailsModal = ({ items = [], title = '', onDismiss }) => (
	<Modal onBackgroundClick={onDismiss}>
		<TopBar onClose={onDismiss} />
		<DetailContainer>
			<Title>{title}</Title>
			{items.map(item => (
				<div key={item.id}>
					<FeatureItem>{item.name}</FeatureItem>
					{featureHasMeta(item) && (
						<MetaItems>
							{!!item.meta.string && <MetaItem>{addBullet(item.meta.string)}</MetaItem>}
							{!!item.meta.select && <MetaItem>{addBullet(item.meta.select)}</MetaItem>}
							{!!item.meta.multi_select &&
								item.meta.multi_select.map((value, i) => (
									<MetaItem key={i}>{addBullet(value)}</MetaItem>
								))}
						</MetaItems>
					)}
					<Divider />
				</div>
			))}
		</DetailContainer>
	</Modal>
);

export default ListingFeaturesDetailsModal;

const FeatureItem = styled.p`
	color: ${colors.grey60};
	font-size: 16px;
	margin-bottom: 24px;
`;

const DetailContainer = styled.div`
	overflow: auto;
	height: calc(100vh - ${topBar.height});
	margin-bottom: 30px;
	padding-top: 24px;
	padding-left: 24px;
	padding-right: 24px;
`;

const MetaItems = styled.ul`
	margin-top: -16px;
	margin-left: 8px;
	list-style: none;
	color: ${colors.grey60};
`;

const MetaItem = styled.li`
	margin-bottom: 12px;
	font-size: 14px;
`;
