import React, { useState } from 'react';

import styled from 'styled-components';

import { Modal } from 'lib/Modal';
import { TopBar } from 'lib/TopBar';
import { FeatureCategory, Room, RoomType } from 'models/property';
import theme from 'config/theme';
import Title from 'lib/Title';
import { BathroomType } from 'components/add-digs/types';
import { ReactComponent as PeopleIcon } from 'assets/images/digs-listing/people.svg';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import listingService from 'services/listingService';
import urlUtils from 'utils/urlUtils';

const { colors } = theme;
const addImgixParams = (url: string) =>
	urlUtils.appendQueryParams(url, `auto=compress&auto=format`);

interface RoomDetailsModalProps {
	room: Room;
	onClose(): void;
}

const RoomDetailsModal = ({ room, onClose }: RoomDetailsModalProps) => {
	const [selectedPhoto, setSelectedPhoto] = useState(room.photos[0]);
	const screenWidth = useScreenWidth();

	const furnishedFeatures = room.features.filter(
		feature =>
			feature.category === FeatureCategory.Furniture ||
			feature.category === FeatureCategory.Beds ||
			feature.category === FeatureCategory.Amenities,
	);

	const bathroomFeatures = room.features.filter(
		feature => feature.category === FeatureCategory.Bathroom,
	);

	return (
		<Modal width={1052} onBackgroundClick={onClose}>
			<TopBar
				closeOnRight
				title={room.title}
				subTitle={room.bedroom_type === RoomType.Shared ? 'Shared room' : 'Private room'}
				onClose={onClose}
			/>
			<MainContent>
				<LeftColumn>
					{room.bedroom_type === RoomType.Shared && Boolean(room.guests) && (
						<>
							<Title standardMargin>Capacity</Title>
							<FeaturesSection>
								<FeatureContainer>
									<PeopleIcon />
									<FeatureLabel>{room.guests} person sharing</FeatureLabel>
								</FeatureContainer>
							</FeaturesSection>
						</>
					)}
					<Title standardMargin>Furniture</Title>
					<FeaturesSection>
						{furnishedFeatures.map(feature => (
							<FeatureContainer key={feature.id}>
								<FeatureIcon src={feature.url} />
								<FeatureLabel>{listingService.getFeatureDisplayName(feature)}</FeatureLabel>
							</FeatureContainer>
						))}
						{!furnishedFeatures.length && (
							<FeatureLabel noMargin>This room is unfurnished</FeatureLabel>
						)}
					</FeaturesSection>
					{Boolean(bathroomFeatures.length) && (
						<>
							<Title standardMargin>
								Bathroom - {room.bathroom_type === BathroomType.Ensuite ? 'Private' : 'Shared'}
							</Title>
							<FeaturesSection>
								{bathroomFeatures.map(feature => (
									<FeatureContainer key={feature.id}>
										<FeatureIcon src={feature.url} />
										<FeatureLabel>{listingService.getFeatureDisplayName(feature)}</FeatureLabel>
									</FeatureContainer>
								))}
							</FeaturesSection>
						</>
					)}
					{screenWidth <= BREAKPOINT_TABLET_PX && Boolean(room.photos.length) && (
						<>
							<Title standardMargin>Pictures</Title>
							{room.photos.map(photo => (
								<MobileImage key={photo.url} src={addImgixParams(photo.url)} />
							))}
						</>
					)}
				</LeftColumn>
				{selectedPhoto && (
					<ColumnRight>
						<MainImage src={selectedPhoto.url} />
						<BottomImagesContainer>
							{room.photos.map(photo => (
								<BottomImageContainer key={photo.url}>
									<BottomImage
										src={addImgixParams(photo.url)}
										selected={photo.url === selectedPhoto.url}
										onClick={() => setSelectedPhoto(photo)}
									/>
								</BottomImageContainer>
							))}
						</BottomImagesContainer>
					</ColumnRight>
				)}
			</MainContent>
		</Modal>
	);
};

const MainContent = styled.div`
	display: flex;
	padding: 24px;
	overflow-y: auto;
`;

const LeftColumn = styled.div`
	width: 50%;
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 16px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		width: 100%;
		padding: 0;
	}
`;

const ColumnRight = styled(LeftColumn)`
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;

const MainImage = styled.img`
	width: 100%;
	max-height: 330px;
	object-fit: cover;
	border-radius: 16px;
`;

const BottomImagesContainer = styled.div`
	display: flex;
	flex: 1;
	margin: 0 -8px;
	overflow: hidden;
	margin-top: 16px;
`;

const BottomImageContainer = styled.div`
	padding: 0 8px;
	max-height: 80px;
	width: 25%;
`;

const BottomImage = styled.img<{ selected?: boolean }>`
	cursor: pointer;
	height: 80px;
	width: 100%;
	object-fit: cover;
	border-radius: 16px;
	border: 2px solid ${({ selected }) => (selected ? colors.pink : 'transparent')};
`;

const FeatureLabel = styled.div<{ noMargin?: boolean }>`
	color: ${colors.grey60};
	margin-left: ${({ noMargin }) => (noMargin ? '0' : '16px')};
`;

const FeaturesSection = styled.div`
	margin-bottom: 24px;
	display: flex;
	flex-wrap: wrap;
`;

const FeatureContainer = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;

const FeatureIcon = styled.img`
	height: 24px;
`;

const MobileImage = styled.img`
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: 16px;
	margin-bottom: 16px;
`;

export default RoomDetailsModal;
