import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validationMessage from 'config/validation-messages';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { Formik } from 'formik';
import api from 'api';
import authContainer from 'containers/auth';
import Input from 'components/common/input';
import NProgress from 'nprogress';
import { Subscribe } from 'unstated';
import modalContainer from 'containers/modal';
import { Button } from 'lib/Button';

const messageHostSchema = yup.object().shape({
	message: yup
		.string()
		.min(10, validationMessage.shortHostMessage)
		.max(750, validationMessage.longHostMessage)
		.required(validationMessage.required),
});

class MessageLandlordForm extends Component {
	static propTypes = {
		listingId: PropTypes.string.isRequired,
		auth: PropTypes.object,
		setModal: PropTypes.func,
		price: PropTypes.number,
	};

	fireSubmissionStatus = isSuccessful => {
		if (isSuccessful) {
			Swal.fire({
				title: 'Message sent successfully',
				type: 'success',
				timer: 3000,
				showConfirmButton: false,
			});
		} else {
			Swal.fire({
				title: 'An error occurred sending your message',
				type: 'warning',
				showConfirmButton: true,
			});
		}
	};

	render() {
		const { listingId, auth, setModal } = this.props;

		return (
			<Formik
				initialValues={{ message: '' }}
				validationSchema={messageHostSchema}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					if (!auth.token) {
						setModal('login');
						return;
					}

					NProgress.start();

					try {
						const payload = {
							reference_type: 'search_listing',
							reference_value: listingId,
							message: values.message,
						};

						const config = {
							body: payload,
						};

						const { createMessage } = api.messagingV2;
						const response = await createMessage(config);

						if (response.status === 201) {
							this.fireSubmissionStatus(true);
							resetForm();
						} else {
							this.fireSubmissionStatus(false);
						}
					} catch (error) {
						this.fireSubmissionStatus(false);
						console.log(error);
					} finally {
						NProgress.done();
						setSubmitting(false);
					}
				}}
			>
				{({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
					<form onSubmit={handleSubmit}>
						<Input
							name="message"
							type="text"
							placeholder="Message"
							value={values.message}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.message && touched.message && errors.message}
						/>

						<div className="columns ml-0 mr-0">
							<div className="column is-6 is-offset-6 pl-0 pr-0">
								<Button isOutline type="submit">
									Message
								</Button>
							</div>
						</div>
					</form>
				)}
			</Formik>
		);
	}
}

export default function MessageLandlordFormWithAuthAndModal(props) {
	return (
		<Subscribe to={[authContainer, modalContainer]}>
			{(auth, modal) => <MessageLandlordForm {...props} auth={auth.state} setModal={modal.set} />}
		</Subscribe>
	);
}
