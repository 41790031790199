import React, { Component } from 'react';
import FullScreenModal from '../../common/fullscreen-modal';
import styled from 'styled-components';
import { coverImage } from 'config/mixins';
import TrustChecks from './TrustChecks';
import theme from 'config/theme';
import HorizontalDivider from '../HorizontalDivider';
import MessageLandlordForm from './MessageLandlordForm';
import { TopBar } from '../TopBar';

const { colors, topBar } = theme;

class LandlordDetailsMobile extends Component {
	renderLandlordProfile = () => {
		const { first_name, profile_picture, join_date } = this.props;
		return (
			<ProfileWrapper>
				<div>
					<Title>Hi, I'm {first_name}</Title>
					<JoiningDateText>Joined in {join_date.substring(0, 4)}</JoiningDateText>
				</div>
				<Picture src={profile_picture} />
			</ProfileWrapper>
		);
	};

	renderLandlordDescription = () => {
		const { biography } = this.props;

		return (
			<>
				<SectionTitle>About</SectionTitle>
				<DescriptionText>{biography}</DescriptionText>
			</>
		);
	};

	renderVerifications = () => {
		const { verifications } = this.props;
		return (
			<>
				<SectionTitle>Verifications</SectionTitle>
				<TrustChecks verifications={verifications} />
			</>
		);
	};

	// TODOS
	// renderLandlordListings = () => {
	//   return (
	//     <>
	//       <SectionTitle>Listings</SectionTitle>
	//       praesentium. Quisquam repellat odio, consequuntur eaque saepe totam,
	//       aperiam quaerat earum ipsa ipsam id eligendi eos, nihil quidem quo?
	//       Consequat
	//     </>
	//   );
	// };

	render() {
		const { show, onDismiss, listingId } = this.props;
		return (
			<FullScreenModal showModal={show} onRequestClose={onDismiss}>
				<TopBar onClose={onDismiss} />
				<DetailContainerMobile>
					{this.renderLandlordProfile()}
					{this.renderLandlordDescription()}
					<HorizontalDivider />
					{this.renderVerifications()}
					{/* <HorizontalDivider />
            {this.renderLandlordListings()} */}
					<HorizontalDivider />
					<MessageLandlordForm listingId={listingId} />
				</DetailContainerMobile>
			</FullScreenModal>
		);
	}
}

export default LandlordDetailsMobile;

/**styled components */

const DetailContainerMobile = styled.div`
	overflow: auto;
	height: calc(100vh - ${topBar.height});
	margin-bottom: 110px;
	padding-top: 16px;
	padding-left: 24px;
	padding-right: 24px;
`;

const Title = styled.h2`
	color: ${colors.darkBlue};
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 8px;
`;

const JoiningDateText = styled.p`
	color: ${colors.darkTurquoise};
	font-size: 14px;
	font-weight: 400;
`;

const Picture = styled.div`
	${coverImage}
	width: 72px;
	height: 72px;
	border-radius: 50%;
	background-color: ${colors.gray};
`;

const ProfileWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 22px;
`;

const SectionTitle = styled.div`
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
	color: ${colors.darkBlue};
`;

const DescriptionText = styled.div`
	color: ${colors.grey60};
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 32px;
`;
