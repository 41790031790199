import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { ReactComponent as LeaseIcon } from 'assets/images/digs-listing/lease.svg';
import { ReactComponent as CouchIcon } from 'assets/images/icons/couch.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/digs-listing/people.svg';
import { ReactComponent as BathIcon } from 'assets/images/digs-listing/bath.svg';
import { ReactComponent as BedIcon } from 'assets/images/digs-listing/bed.svg';
import { ReactComponent as M2Icon } from 'assets/images/digs-listing/m2.svg';
import { FurnishedType, LeaseType, Listing } from 'models/listing';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors } = theme;

interface PopularFeaturesBlockProps {
	listing: Listing;
}

const PopularFeaturesBlock = ({ listing }: PopularFeaturesBlockProps) => {
	const isFurnished =
		listing.furnished === FurnishedType.Fully || listing.furnished === FurnishedType.Semi;
	return (
		<Container>
			<ItemContainer>
				<LeaseIcon />
				<ItemLabel>
					{listing.lease_type === LeaseType.EntirePlace
						? 'Entire place lease'
						: 'Room by room leases'}
				</ItemLabel>
			</ItemContainer>
			{Boolean(listing.property_size) && (
				<ItemContainer>
					<M2Icon />
					<ItemLabel>
						Property size: {listing.property_size}
						{listing.property_size_unit}
					</ItemLabel>
				</ItemContainer>
			)}
			{isFurnished && (
				<ItemContainer>
					<CouchIcon />
					<ItemLabel>Furnished</ItemLabel>
				</ItemContainer>
			)}
			{Boolean(listing.bedroom_count) && (
				<ItemContainer>
					<BedIcon />
					<ItemLabel>
						{listing.bedroom_count}{' '}
						{listing.lease_type === LeaseType.EntirePlace ? 'bedroom' : 'room type'}
						{listing.bedroom_count === 1 ? '' : 's'}
					</ItemLabel>
				</ItemContainer>
			)}
			{Boolean(listing.total_shared_bathrooms) && (
				<ItemContainer>
					<BathIcon />
					<ItemLabel>
						{listing.total_shared_bathrooms} shared bathroom
						{listing.total_shared_bathrooms === 1 ? '' : 's'}
					</ItemLabel>
				</ItemContainer>
			)}
			{Boolean(listing.total_occupants) && (
				<ItemContainer>
					<PeopleIcon />
					<ItemLabel>Max occupants: {listing.total_occupants}</ItemLabel>
				</ItemContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const ItemContainer = styled.div`
	display: flex;
	width: 33%;
	margin-bottom: 16px;
	align-items: center;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		width: 50%;
		margin-bottom: 12px;
	}
`;

const ItemLabel = styled.p`
	color: ${colors.grey60};
	font-weight: 500;
	font-size: 14px;
	margin: 0;
	margin-left: 16px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		font-size: 12px;
		margin-left: 12px;
	}
`;

export default PopularFeaturesBlock;
