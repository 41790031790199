import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import theme from 'config/theme';

const { zIndices } = theme;

class FullScreenModal extends Component {
	static propTypes = {
		showModal: PropTypes.bool.isRequired,
		onRequestClose: PropTypes.func, //callback for when user clicks overlay or esc character
		children: PropTypes.node,
	};

	render() {
		const { showModal, onRequestClose, children } = this.props;

		const styles = {
			overlay: {
				zIndex: zIndices.modal,
			},
			content: {
				position: 'absolute',
				top: '0',
				left: '0',
				right: '0',
				bottom: '0',
				border: 'none',
				background: '#fff',
				overflow: 'none',
				WebkitOverflowScrolling: 'touch',
				borderRadius: '0px',
				padding: 0,
			},
		};

		return (
			<ReactModal isOpen={showModal} onRequestClose={onRequestClose} style={styles}>
				{children}
			</ReactModal>
		);
	}
}
export default FullScreenModal;
