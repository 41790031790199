import React, { useEffect, useState } from 'react';

import { Gesture } from 'react-with-gesture';
import styled from 'styled-components';
import _ from 'lodash';
import queryString from 'query-string';
import 'lazysizes';

import { flexCenter } from 'config/mixins';
import theme from 'config/theme';
import { ReactComponent as RightArrow } from 'assets/images/digs-listing/right-arrow.svg';
import { ReactComponent as LeftArrow } from 'assets/images/digs-listing/left-arrow.svg';

const { colors, boxShadow } = theme;

const width =
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const arrowSize = 16;

const ImageCarousel = ({
	images = [],
	swipeThreshold = 60,
	minSwipeThreshold = 30,
	height = '100%',
	fullHeightImage = true,
	showDirectionalArrows = false,
	greyBackground = true,
	onImageClick = url => {},
	onImageView = () => {},
	className,
	startingIndex = 0,
}) => {
	const [currentPosition, setCurrentPosition] = useState(startingIndex);

	useEffect(() => {
		onImageView(currentPosition);
		// TODO: Fix and test
		// eslint-disable-next-line
	}, [currentPosition]);

	const setPosition = position => {
		if (images.length <= 1) return;
		const newPosition = position < 0 ? images.length - 1 : position % images.length;

		setCurrentPosition(newPosition);
	};

	const handleGestureUp = _.debounce(({ distance, direction, delta }) => {
		const isHorizontalSwipe = Math.abs(direction[0]) > Math.abs(direction[1]);

		if (distance > swipeThreshold && isHorizontalSwipe) {
			const change = direction[0] > 0 ? -1 : 1;
			setCurrentPosition(currentPosition => {
				const nextPosition = currentPosition + change;
				return nextPosition < 0 ? images.length - 1 : nextPosition % images.length;
			});
			return;
		}
		if (delta[0] === 0 && delta[1] === 0) {
			//a regular click
			setCurrentPosition(currentPosition => {
				const url = queryString.parseUrl(images[currentPosition]).url;
				onImageClick(url, currentPosition);
				return currentPosition;
			});
		}
	}, 50); //this method has been debounced as some error is causing it to fire twice in quick succession when clicked

	return (
		<CarouselContainer className={className} height={height} greyBackground={greyBackground}>
			<PreviewContainer>
				<Gesture onUp={handleGestureUp}>
					{({ down, delta }) =>
						images &&
						images.map((image, i) => {
							const itemPosition = (i - currentPosition) % images.length;
							const aboveMinSwipeThreshold = Math.abs(delta[0]) > minSwipeThreshold;

							return (
								<ItemContainer
									key={image}
									style={
										down && aboveMinSwipeThreshold
											? {
													cursor: 'grabbing',
													transition: 'transform 30ms',
													transform: `translateX(calc(${itemPosition * 100}% + ${delta[0]}px))`,
											  }
											: {
													transform: `translateX(${itemPosition * 100}%)`,
											  }
									}
								>
									<Image
										draggable={false}
										fullHeight={fullHeightImage}
										src={`${image.split('?')[0]}?auto=compress&auto=format&w=${width}`}
									/>
								</ItemContainer>
							);
						})
					}
				</Gesture>
				{!!showDirectionalArrows && (
					<ArrowsWrapper>
						<ArrowItem onClick={() => setPosition(currentPosition - 1)}>
							<LeftArrow width={arrowSize} height={arrowSize} />
						</ArrowItem>
						<ArrowItem onClick={() => setPosition(currentPosition + 1)}>
							<RightArrow width={arrowSize} height={arrowSize} />
						</ArrowItem>
					</ArrowsWrapper>
				)}
				<PositionContainer>
					{images.map((image, i) => (
						<PositionMarker
							key={image}
							onClick={() => setPosition(i)}
							selected={currentPosition === i}
						/>
					))}
				</PositionContainer>
			</PreviewContainer>
		</CarouselContainer>
	);
};

export default ImageCarousel;

const Image = styled.img`
	user-select: none;
	user-drag: none;
	pointer-events: none;

	${({ fullHeight }) =>
		fullHeight
			? `
    object-fit: cover;
    height: 100%;
    width: 100%;
  `
			: `
    max-width: 100%;
    max-height: 100%;
  `}
`;

const ItemContainer = styled.li`
	${flexCenter}
	width: 100%;
	height: 100%;
	position: absolute;
	transition: transform 1s;
	user-select: none;
	overflow: hidden;
`;

const CarouselContainer = styled.div`
	width: 100%;
	height: ${props => (typeof props.height === 'number' ? `${props.height}px` : props.height)};
	display: flex;
	flex-direction: column;
	background: ${({ greyBackground }) => (greyBackground ? colors.lightGray : colors.white)};
`;

const PreviewContainer = styled.ul`
	${flexCenter}
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	max-height: 100%;
	max-width: 100%;
`;

const PositionContainer = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 16px;
	width: 100%;
`;

const PositionMarker = styled.div`
	margin-left: 2px;
	margin-right: 2px;
	padding: 0;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
	background: ${colors.white};
	transition: background 0.5s ease;
	cursor: pointer;

	${({ selected }) => selected && `background: ${colors.pink}`}
`;

const ArrowsWrapper = styled.div`
	position: absolute;
	top: calc(50% - 16px);
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-left: 20px;
	padding-right: 16px;
`;

const ArrowItem = styled.div`
	background-color: ${colors.white};
	box-shadow: ${boxShadow.small};
	border: 1px solid ${colors.grey10};
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`;
