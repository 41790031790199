import styled from 'styled-components';

const StyledStandardLayoutWrapper = styled.div`
	margin: auto;
	max-width: 100%;
	padding-left: 24px;
	padding-right: 24px;

	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1400px) {
		max-width: 1280px;
	}
`;
export default StyledStandardLayoutWrapper;
