import React from 'react';

import { RoomCarousel } from 'components/RoomCarousel';
import { SingleRoomCard } from 'components/SingleRoomCard';
import { Currency, Listing } from 'models/listing';
import { Room } from 'models/property';

interface SingleRoomCardsProps {
	rooms: any[];
	currency: Currency;
	listing: Listing;
	maxHorizontalCards?: number;
	index?: number;
	onRoomClick?(room: Room): void;
}

export const SingleRoomCards = ({
	rooms,
	currency,
	listing,
	maxHorizontalCards,
	index,
	onRoomClick,
}: SingleRoomCardsProps) => (
	<RoomCarousel
		showRoomDetails
		showRoomAvailability
		rooms={rooms}
		iconSize={18}
		maxHorizontalCards={maxHorizontalCards || 2}
		CardComponent={SingleRoomCard}
		iconTop={80}
		currency={currency}
		listing={listing}
		index={index}
		onRoomClick={onRoomClick}
	/>
);
