import React from 'react';

import styled from 'styled-components';
import { ReactComponent as RightArrow } from 'assets/images/listing/arrow-right.svg';
import { ReactComponent as LeftArrow } from 'assets/images/listing/arrow-left.svg';

import theme from 'config/theme';

const { colors } = theme;

const Container = styled.div`
	display: flex;
`;

const ButtonContainer = styled.div`
	min-width: 40px;
	min-height: 40px;
	border-radius: 50%;
	background: ${colors.darkBlue};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0 8px;
`;

interface ArrowButtonsProps {
	showRight: boolean;
	showLeft: boolean;
	onArrowClick(arrow: 'left' | 'right'): void;
}

const ArrowButtons = ({ showLeft, showRight, onArrowClick }: ArrowButtonsProps) => (
	<Container>
		{showLeft && (
			<ButtonContainer onClick={() => onArrowClick('left')}>
				<LeftArrow />
			</ButtonContainer>
		)}
		{showRight && (
			<ButtonContainer onClick={() => onArrowClick('right')}>
				<RightArrow />
			</ButtonContainer>
		)}
	</Container>
);

export default ArrowButtons;
