import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breakpoint } from '@tiaanduplessis/react-resize';

import ImageOverviewDesktop from './ImageOverviewDesktop';
import ListingGallery from './listing-gallery/ListingGallery';
import ImageCarousel from '../ImageCarousel';
import { getCarouselImages, getDesktopOverviewImages } from './imageOverviewHelpers';

class ImageOverview extends Component {
	static propTypes = {
		images: PropTypes.object,
	};

	static defaultProps = {
		images: {},
	};

	state = { showMore: false };

	showMore = () => {
		this.setState({ showMore: true });
	};

	hideMore = () => {
		this.setState({ showMore: false });
	};

	render() {
		const mobileBreakPointPx = 490;

		const { images, videos } = this.props;
		const { showMore } = this.state;

		return (
			<>
				<Breakpoint maxWidth={mobileBreakPointPx}>
					<ImageCarousel
						images={getCarouselImages(images)}
						onImageClick={this.showMore}
						height={250}
					/>
				</Breakpoint>

				<Breakpoint minWidth={mobileBreakPointPx + 1}>
					<ImageOverviewDesktop
						images={getDesktopOverviewImages(images)}
						onImageClick={this.showMore}
					/>
				</Breakpoint>
				<ListingGallery show={showMore} onDismiss={this.hideMore} images={images} videos={videos} />
			</>
		);
	}
}

export default ImageOverview;
