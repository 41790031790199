import React, { useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as EmailIcon } from 'assets/images/digs-listing/email.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/digs-listing/facebook.svg';
import { ReactComponent as LinkIcon } from 'assets/images/digs-listing/link.svg';
import { ReactComponent as TrustedSvg } from 'assets/images/digs-listing/person-checked.svg';
import { ReactComponent as TimerIcon } from 'assets/images/digs-listing/timer.svg';
import { ReactComponent as MessageSquareIcon } from 'assets/images/digs-listing/message_square.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/digs-listing/calendar.svg';
import theme from 'config/theme';
import { BREAKPOINT_TABLET_LARGE_PX } from 'constants/breakPoints';
import { Button } from 'lib/Button';
import numberFormatService from 'services/numberFormatService';
import shareService from 'services/shareService';
import listingService from 'services/listingService';
import FavouriteButton from 'lib/FavouriteButton';
import routes from 'config/routes';
import { notifySuccess } from 'lib/Notifications';
import { coverImage } from 'config/mixins';
import Divider from 'lib/Divider';
import { ReactComponent as InfoIconSvg } from 'assets/images/icons/info-circle-outline.svg';

import authContainer from 'containers/auth';
import { Subscribe } from 'unstated';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import StackedVerifications from './StackedVerifications';
import { getLandlordDetailsTooltipText, InfoTooltipType } from './landlordDetailsTooltipText';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import ReportListing from 'components/ReportListing';

const { zIndices, colors } = theme;

// TODO: Type correctly
type BookingPanelProps = any;

export const BookingPanel = ({
	price,
	display_price_from,
	bookingInformation,
	nsfas_accredited,
	varsity_college_accredited,
	aie_accredited,
	is_single_lease,
	currency,
	rooms,
	sub_type,
	listing,
	replyRatePercentage,
	responseTimeHours,
	hasOpenEnquiry,
	isPreview,
	onBookNowClick,
	onToggleFavourite,
}: BookingPanelProps) => {
	const subType = listingService.getSubTypeText(sub_type).toLowerCase();

	const iconStyle = {
		marginLeft: 16,
		height: 26,
	};

	const handleCopyToClipboardClick = () => {
		shareService.copyToClipboard(window.location.href);
		notifySuccess('Link copied');
	};

	const handleLoginClick = (): void => {
		modalContainer.set(ModalType.Login, {
			onLoginSuccess: () => {
				modalContainer.set(ModalType.ReportListing, { id: listing?.uuid });
			},
		});
	};

	return (
		<>
			<MobileOnly>
				<StyledBookPanelMobile>
					{hasOpenEnquiry && (
						<MobileHighlightText>You've already enquired here</MobileHighlightText>
					)}
					<MobileBanner>
						<MobileBannerLeft>
							<PriceContainer>
								<div>
									{!!display_price_from && <FromText>From</FromText>}
									<RentalAmount>
										{numberFormatService.formatCurrency(
											price,
											listingService.getCurrencySymbol(currency),
										)}{' '}
										<PaymentPeriod>/ {listingService.getBillingCyclePeriod(listing)}</PaymentPeriod>
									</RentalAmount>
								</div>
							</PriceContainer>
						</MobileBannerLeft>
						<MobileBannerRight>
							<MobileActionBar>
								{isPreview ? (
									<Button className="ua-enquiry-button" height={52} link={routes.manage_digs}>
										Back to listing
									</Button>
								) : (
									<>
										{hasOpenEnquiry ? (
											<Button height={52} link={routes.inbox}>
												View chat
											</Button>
										) : (
											<Button
												className="ua-enquiry-button"
												height={52}
												isPink
												onClick={() => onBookNowClick(bookingInformation)}
											>
												Enquire
											</Button>
										)}
									</>
								)}
								{!isPreview && (
									<MobileFavouriteButton onClick={onToggleFavourite}>
										<FavouriteButton
											isChecked={listing.favourite}
											isClickPropagationDisabled={false}
										/>
									</MobileFavouriteButton>
								)}
							</MobileActionBar>
						</MobileBannerRight>
					</MobileBanner>
				</StyledBookPanelMobile>
			</MobileOnly>
			<StickyWrapper>
				<PanelDesktop>
					{(Boolean(nsfas_accredited) ||
						Boolean(varsity_college_accredited) ||
						Boolean(aie_accredited)) && (
						<DesktopOnly>
							<BadgesContainer>
								<StackedVerifications
									nsfas_accredited={nsfas_accredited}
									varsity_college_accredited={varsity_college_accredited}
									aie_accredited={aie_accredited}
								/>
							</BadgesContainer>
						</DesktopOnly>
					)}
					<DescriptionText>
						{is_single_lease
							? `Entire ${subType} for rent`
							: `${rooms.length === 1 ? 'Room' : 'Rooms'} in ${subType} for rent`}
					</DescriptionText>
					<PriceRow>
						{!!display_price_from && <FromTextDesktop>from</FromTextDesktop>}
						<RentalText>
							{numberFormatService.formatCurrency(
								price,
								listingService.getCurrencySymbol(currency),
							)}
							<RentalPeriodSpan>/ {listingService.getBillingCyclePeriod(listing)}</RentalPeriodSpan>
						</RentalText>
					</PriceRow>
					{hasOpenEnquiry ? (
						<HightlightText>You've already enquired here</HightlightText>
					) : (
						<AvailabilityText>
							{listing.is_available
								? 'Available now'
								: `Available from ${new Date(listing.availability_date).toDateString()}` +
								  (listing.lease_term_flexible ? ' (Flexible)' : '')}
						</AvailabilityText>
					)}
					<ButtonsContainer>
						{!isPreview && (
							<>
								{hasOpenEnquiry ? (
									<Button isFullWidth link={routes.inbox}>
										View chat
									</Button>
								) : (
									<Button
										className="ua-enquiry-button"
										isFullWidth
										isPink
										onClick={() => onBookNowClick(bookingInformation)}
									>
										Enquire
									</Button>
								)}
								<FavouriteButtonContainer>
									<FavouriteButtonContent>
										<Button isFullWidth isOutline onClick={onToggleFavourite}>
											<FavouriteButton
												isChecked={listing.favourite}
												isClickPropagationDisabled={false}
											/>
										</Button>
									</FavouriteButtonContent>
								</FavouriteButtonContainer>
							</>
						)}
					</ButtonsContainer>
					<Divider />
					<LandlordSection>
						<LandlordSectionLeftPanel>
							<LandlordSectionProfilePicture src={listing.landlord.profile_picture} />
						</LandlordSectionLeftPanel>
						<LandlordSectionRightPanel>
							<LandlordNameText>
								{listing.external ? 'Digs International' : listing.landlord.first_name}
							</LandlordNameText>
							<LandlordStatContainer>
								<LandlordStat>
									<LandlordIconContainer>
										<CalendarIcon fill={colors.darkBlue} width={32} />
									</LandlordIconContainer>
									<LandlordStatText>
										Joined DigsConnect in {listing.landlord.join_date.split('-')[0]}
									</LandlordStatText>
								</LandlordStat>
							</LandlordStatContainer>
							{!!responseTimeHours && (
								<LandlordStatContainer>
									<LandlordStat>
										<LandlordIconContainer>
											<TimerIcon fill={colors.darkBlue} width={32} />
										</LandlordIconContainer>
										<LandlordStatText>Replies in {responseTimeHours} hours</LandlordStatText>
									</LandlordStat>
									<InfoIcon />
									<InfoTextPopup>
										{getLandlordDetailsTooltipText(InfoTooltipType.ResponseTime)}
									</InfoTextPopup>
								</LandlordStatContainer>
							)}
							{!!replyRatePercentage && (
								<LandlordStatContainer>
									<LandlordStat>
										<LandlordIconContainer>
											<MessageSquareIcon fill={colors.darkBlue} width={32} />
										</LandlordIconContainer>
										<LandlordStatText>{replyRatePercentage}% reply rate</LandlordStatText>
									</LandlordStat>
									<InfoIcon />
									<InfoTextPopup>
										{getLandlordDetailsTooltipText(InfoTooltipType.ReplyRate)}
									</InfoTextPopup>
								</LandlordStatContainer>
							)}
						</LandlordSectionRightPanel>
					</LandlordSection>
					{listing.landlord.is_trusted_landlord && (
						<TrustedLandlordSection>
							<TrustedLandlordTextContainer>
								<TrustedLandlordIcon />
								Trusted landlord
							</TrustedLandlordTextContainer>
							<InfoIcon isWhite />
							<TrustedLandlordInfoTextPopup>
								{getLandlordDetailsTooltipText(InfoTooltipType.TrustedLandlord)}
							</TrustedLandlordInfoTextPopup>
						</TrustedLandlordSection>
					)}
				</PanelDesktop>
				<ShareContainer>
					<ShareListingLabel>Share this listing</ShareListingLabel>
					<ShareIconsContainer>
						<LinkIcon onClick={handleCopyToClipboardClick} style={iconStyle} />
						<a href={shareService.getEmailShareLink(window.location.href)}>
							<EmailIcon style={iconStyle} />
						</a>
						<a
							href={shareService.getFacebookShareLink(window.location.pathname)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FacebookIcon style={iconStyle} />
						</a>
					</ShareIconsContainer>
				</ShareContainer>
				<Subscribe to={[authContainer]}>
					{auth => (
						<ReportListing
							showLoginPrompt={!auth.state.token}
							onLoginClick={handleLoginClick}
							listingId={listing.uuid}
						/>
					)}
				</Subscribe>
			</StickyWrapper>
		</>
	);
};

const MobileOnly = styled.div`
	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX + 1}px) {
		display: none;
	}
`;

const DesktopOnly = styled.div`
	@media (max-width: ${BREAKPOINT_TABLET_LARGE_PX}px) {
		display: none;
	}
`;

const StickyWrapper = styled(DesktopOnly)`
	position: -webkit-sticky;
	position: sticky;
	top: 120px;
`;

const PanelDesktop = styled.div`
	box-shadow: 0 3px 16px rgba(4, 31, 71, 0.08);
	background-color: ${colors.white};
	padding: 24px;
	border-radius: 16px;
	border: 1px solid ${colors.grey10};
`;

const DescriptionText = styled.div`
	color: ${colors.grey60};
	margin-bottom: 8px;
	font-size: 14px;
`;

const RentalText = styled.p`
	font-size: 24px;
	color: ${colors.darkBlue};
	margin-bottom: 5px;
	font-weight: 700;
`;

const RentalPeriodSpan = styled.span`
	font-weight: 400;
	color: ${colors.grey60};
	font-size: 14px;
	margin-left: 2px;
`;

const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const StyledBookPanelMobile = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${colors.white};
	box-shadow: 0 -3px 16px rgba(159, 159, 159, 0.16);
	z-index: ${zIndices.footer};
	padding: 12px 24px;
`;

const PriceContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

const RentalAmount = styled.div`
	font-size: 14px;
	font-weight: 600;
	line-height: 12px;
`;

const PaymentPeriod = styled.span`
	font-weight: 400;
	font-size: 12px;
	color: ${colors.grey60};
`;

const FromText = styled.div`
	font-size: 12px;
	font-weight: 400;
	color: ${colors.grey60};
	padding-bottom: 8px;
`;

const FromTextDesktop = styled(FromText)`
	font-size: 16px;
	margin: 0;
	margin-right: 8px;
	padding: 0;
	color: ${colors.darkBlue};
`;

const FavouriteButtonContainer = styled.div`
	display: flex;
	width: 52px;
	height: 52px;
`;

const FavouriteButtonContent = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const FavouriteTextContainer = styled.div`
	flex: 1;
`;

const MobileFavouriteButton = styled.div`
	border: 2px solid ${({ color }) => color || colors.darkBlue};
	border-radius: 16px;
	height: 52px;
	min-width: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 16px;
	cursor: pointer;
`;

const MobileActionBar = styled.div`
	display: flex;
`;

const MobileBanner = styled.div`
	display: flex;
`;

const MobileBannerLeft = styled.div`
	flex: 4;
`;

const MobileBannerRight = styled.div`
	flex: 6;
`;

const HightlightText = styled.p`
	color: ${colors.pink};
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 16px;
	line-height: 20px;
`;

const MobileHighlightText = styled(HightlightText)`
	text-align: center;
	margin-bottom: 8px;
	margin-top: -8px;
`;

const ShareContainer = styled.div`
	background: ${colors.white};
	border-radius: 16px;
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 3px 16px rgba(4, 31, 71, 0.08);
	padding: 0 24px;
	margin-top: 16px;
	border: 1px solid ${colors.grey10};
`;

const ShareListingLabel = styled.div`
	color: ${colors.darkBlue};
	font-size: 14px;
	font-weight: 600;
`;

const ShareIconsContainer = styled.div`
	display: flex;
	cursor: pointer;
`;

const TrustedLandlordSection = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${colors.darkTurquoise};
	margin-left: -24px;
	margin-bottom: -24px;
	margin-right: -24px;
	margin-top: 16px;
	padding: 12px 24px;
	color: ${colors.white};
	font-weight: 600;
	font-size: 12px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
`;

const TrustedLandlordTextContainer = styled.div`
	display: flex;
	align-items: center;
`;

const TrustedLandlordIcon = styled(TrustedSvg)`
	margin-right: 16px;
`;

const LandlordSection = styled.div`
	margin-top: -12px;
	display: flex;
`;

const LandlordSectionLeftPanel = styled.div`
	width: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const LandlordSectionRightPanel = styled.div`
	flex: 1;
`;

const LandlordSectionProfilePicture = styled.img`
	${coverImage}
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: ${colors.gray};
	margin-top: 8px;
	margin-bottom: 8px;
`;

const LandlordNameText = styled.p`
	color: ${colors.darkBlue};
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 8px;
	margin-left: 8px;
`;

const LandlordStatContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
`;

const LandlordStat = styled.div`
	margin-right: 16px;
	display: flex;
	align-items: center;
`;

const LandlordStatText = styled.div`
	color: ${colors.darkBlue};
	font-weight: 600;
	font-size: 12px;
`;

const LandlordIconContainer = styled.div`
	margin-right: 4px;
`;

const AvailabilityText = styled.div`
	color: ${colors.pink};
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 16px;
`;

const PriceRow = styled.div`
	display: flex;
	align-items: center;
`;

const BadgesContainer = styled.div`
	position: relative;
`;

const InfoTextPopup = styled.div`
	position: absolute;
	border: 1px solid ${colors.grey20};
	padding: 16px;
	border-radius: 12px;
	background: ${colors.white};
	right: 0;
	left: -100px;
	top: 24px;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	visibility: hidden;
`;

const InfoIcon = styled(InfoIconSvg)<{ isWhite?: boolean }>`
	width: 16px;
	height: 16px;
	fill: ${({ isWhite }) => (isWhite ? colors.white : colors.grey60)};
	cursor: pointer;
	margin-right: 8px;

	&:hover + ${InfoTextPopup} {
		visibility: visible;
	}
`;

const TrustedLandlordInfoTextPopup = styled(InfoTextPopup)`
	bottom: 40px;
	left: 16px;
	right: 16px;
	top: unset;
`;
