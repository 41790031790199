import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Divider } from 'lib/Divider';
import { Button } from 'lib/Button';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors } = theme;
const SHOW_MORE_THRESHOLD = 10;

const FeatureSection = ({
	items = [],
	title = '',
	hasDivider,
	hasTopDivider,
	showMore,
	onShowMoreButtonClick,
	currency,
}) => {
	const limitedItems = showMore ? items : items.slice(0, SHOW_MORE_THRESHOLD);
	const isShowMoreVisible = items.length > SHOW_MORE_THRESHOLD;

	if (items.length < 1) {
		return null;
	}

	const displayFeatureMeta = (name, meta) => {
		// TODO: Replace this function with listingService.getFeatureDisplayName

		if (name === 'Landlord admin fee' && meta.string) {
			const currencySymbol = currency && currency.symbol;
			if (!currencySymbol || meta.string.startsWith(currencySymbol)) {
				return ` (${meta.string})`;
			}
			return ` (${currencySymbol}${meta.string})`;
		}

		if (name === 'Security deposit') {
			if (meta.string && meta.select === 'Enter own value') {
				const currencySymbol = currency && currency.symbol;
				if (!currencySymbol || meta.string.startsWith(currencySymbol)) {
					return ` (${meta.string})`;
				}
				return ` (${currencySymbol}${meta.string})`;
			}
			if (meta.select) {
				return ` (${meta.select})`;
			}
		}

		return '';
	};

	return (
		<>
			{hasTopDivider && <Divider />}
			<SubTitle>{title}</SubTitle>
			<Features>
				{limitedItems.map(({ id, name, url, meta }) => (
					<Feature key={id}>
						<>{url ? <Icon src={url} alt="•" /> : <Bullet>•</Bullet>}</>
						<FeatureText>
							{name}
							{displayFeatureMeta(name, meta)}
						</FeatureText>
					</Feature>
				))}
			</Features>
			{isShowMoreVisible && (
				<Button isOutline noMargin onClick={onShowMoreButtonClick}>
					{showMore ? 'Hide' : `Show all ${items.length}`}
				</Button>
			)}
			{hasDivider && <Divider />}
		</>
	);
};

export default FeatureSection;

const SubTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 24px;
`;

const FeatureText = styled.p`
	color: ${colors.grey60};
	font-size: 16px;

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

const Features = styled.div`
	display: flex;
	margin-bottom: 24px;
	flex-wrap: wrap;

	@media (max-width: 768px) {
		flex-direction: row;
	}
`;

const Feature = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;

const Bullet = styled.div`
	margin-right: 8px;
	color: ${colors.grey60};
	font-size: 20px;
`;

const Icon = styled.img`
	margin-right: 8px;
`;
