import React, { Component } from 'react';
import PropTypes from 'prop-types';
import theme from 'config/theme';
import styled from 'styled-components';
import { coverImage } from 'config/mixins';

class ImageOverviewDesktop extends Component {
	static propTypes = {
		images: PropTypes.array,
		onImageClick: PropTypes.func.isRequired,
	};
	static defaultProps = {
		images: [],
	};

	render() {
		const { images, onImageClick } = this.props;
		return (
			<StyledImageOverviewDesktop>
				<InnerContainer>
					<LeftImage
						src={images[0] ? images[0].split('?')[0] + `?auto=format&w=1100` : ''}
						onClick={onImageClick}
					/>
					<RightImagesContainer>
						<RightImage top src={images[1]} onClick={onImageClick} />
						<RightImage top right src={images[2]} onClick={onImageClick} />
						<RightImage src={images[3]} onClick={onImageClick} />
						<RightImage src={images[4]} bottom right onClick={onImageClick}>
							<ViewAllButton onClick={onImageClick}>View all</ViewAllButton>
						</RightImage>
					</RightImagesContainer>
				</InnerContainer>
			</StyledImageOverviewDesktop>
		);
	}
}

export default ImageOverviewDesktop;

/**styled component*/
const { colors, fonts } = theme;

const StyledImageOverviewDesktop = styled.div`
	display: flex;
	width: 100%;
	height: 30vh;
	margin: 24px auto;
	margin-bottom: 8px;
	max-width: 100%;
	padding-left: 24px;
	padding-right: 24px;
	max-height: 400px;

	@media (min-width: 700px) {
		height: 40vh;
	}

	@media (min-width: 850px) {
		height: 50vh;
	}

	@media (min-width: 768px) {
		max-width: 720px;
	}

	@media (min-width: 992px) {
		max-width: 960px;
	}

	@media (min-width: 1400px) {
		max-width: 1280px;
	}
`;

const InnerContainer = styled.div`
	width: 100%;
	width: 100%;
	display: flex;
	border-radius: 16px;
	overflow: hidden;
`;

const LeftImage = styled.div`
	${coverImage}
	height: 100%;
	margin-right: 8px;
	width: calc(50% - 8px);
	cursor: pointer;
`;

const RightImagesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 50%;
	height: 100%;
`;

const RightImage = styled.div`
  ${coverImage};
  width: calc(50% - 4px);
  height: calc(50% - 4px);

  ${({ top }) => top && ` margin-bottom: 8px;`}
  ${({ right }) => right && ` margin-left: 8px;`}
  ${({ bottom, right }) => bottom && right && 'position:relative;'}
  cursor: pointer;
`;

const ViewAllButton = styled.button`
	cursor: pointer;
	border: none;
	background-color: ${colors.white};
	padding: 12px 16px;
	margin: 16px;
	color: ${colors.darkBlue};
	font-weight: 700;
	font-family: ${fonts.Gilroy};
	position: absolute;
	bottom: 0px;
	right: 0px;
	border-radius: 8px;

	&:hover {
		opacity: 0.9;
	}

	&:focus {
		outline: none;
	}
`;
