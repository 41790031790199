import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';

const { colors, boxShadow } = theme;
const size = '40px';

const VerificationContainer = styled.div<{ clickable?: boolean }>`
	margin-right: 8px;
	min-width: ${size};
	background-color: ${colors.white};
	box-shadow: ${boxShadow.small};
	border: 1px solid ${colors.grey10};
	border-radius: 50%;
	width: ${size};
	height: ${size};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
`;

const Verification = styled.img`
	object-fit: contain;
`;

interface VerificationBadgeProps {
	logoUrl: string;
	onClick?: () => void;
}

export const VerificationBadge = ({ logoUrl, onClick }: VerificationBadgeProps) => (
	<VerificationContainer clickable={!!onClick} onClick={onClick}>
		<Verification src={logoUrl} />
	</VerificationContainer>
);
