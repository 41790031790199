import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { TenantGender, TenantType } from 'models/property';
import { SelectOption } from 'models/selectOption';
import { ReactComponent as MaleIcon } from 'assets/images/digs-listing/male.svg';
import { ReactComponent as FemaleIcon } from 'assets/images/digs-listing/female.svg';
import { ReactComponent as PersonIcon } from 'assets/images/digs-listing/person.svg';

const { colors } = theme;

const tenantTypeOptions: SelectOption[] = [
	{ value: TenantType.Undergrad, label: 'Undergrad students' },
	{ value: TenantType.Postgrad, label: 'Postgrad students' },
	{ value: TenantType.YoungProfessional, label: 'Young professionals' },
	{ value: TenantType.DigitalNomad, label: 'Digital nomads' },
	{ value: TenantType.ExchangeStudent, label: 'Exchange students' },
	{ value: TenantType.Expat, label: 'Expats' },
];

interface TenantPreferencesProps {
	preferredGender: TenantGender;
	tenantTypes: TenantType[];
}

const TenantPreferences = ({ preferredGender, tenantTypes }: TenantPreferencesProps) => {
	return (
		<>
			<SubTitle>Preferred tenants</SubTitle>
			<ItemsContainer>
				{preferredGender === TenantGender.All && (
					<ItemContainer>
						<PersonIcon />
						<ItemText>Any gender</ItemText>
					</ItemContainer>
				)}
				{preferredGender === TenantGender.MaleOnly && (
					<ItemContainer>
						<MaleIcon />
						<ItemText>Male only</ItemText>
					</ItemContainer>
				)}
				{preferredGender === TenantGender.FemaleOnly && (
					<ItemContainer>
						<FemaleIcon />
						<ItemText>Female only</ItemText>
					</ItemContainer>
				)}
				{tenantTypes.map(type => (
					<ItemContainer key={type}>
						<PersonIcon />
						<ItemText>
							{tenantTypeOptions.find(t => t.value === type.trim().toLowerCase())?.label || type}
						</ItemText>
					</ItemContainer>
				))}
			</ItemsContainer>
		</>
	);
};

export default TenantPreferences;

const SubTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 24px;
`;

const ItemContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
	width: 50%;
`;

const ItemText = styled.div`
	margin-left: 8px;
	color: ${colors.grey60};
`;

const ItemsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
