import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import './sliding-pane.css';

class SlidingPane extends React.Component {
	static propTypes = {
		showPane: PropTypes.bool.isRequired,
		onRequestClose: PropTypes.func, //callback for when user clicks overlay or esc character
		children: PropTypes.node,
		size: PropTypes.oneOf(['regular', 'large']),
	};

	static defaultProps = {
		size: 'regular',
	};

	getModalClassName = () => {
		const { size } = this.props;

		return {
			base: `sliding-pane-modal-${size}`,
			afterOpen: 'sliding-pane-modal--after-open',
			beforeClose: 'sliding-pane-modal--before-close',
		};
	};

	render() {
		const { showPane, onRequestClose, children } = this.props;

		return (
			<ReactModal
				className={this.getModalClassName()}
				overlayClassName={{
					base: 'sliding-pane-overlay',
					afterOpen: 'sliding-pane-overlay--after-open',
					beforeClose: 'sliding-pane-overlay--before-close',
				}}
				bodyOpenClassName="sliding-pane-modal-body--open"
				htmlOpenClassName="sliding-pane-modal-html--open"
				closeTimeoutMS={700} //always keep value in lockstep with transition durations in sliding-pane.css
				isOpen={showPane}
				onRequestClose={onRequestClose}
			>
				{children}
			</ReactModal>
		);
	}
}

export default SlidingPane;
