import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import Button from 'lib/Button';

const { colors, fontWeights } = theme;
const SHOW_MORE_THRESHOLD = 5;

const CommunitySection = ({ communityData }: any) => {
	const [showMore, setShowMore] = useState(false);
	const [mapData, setMapData] = useState(communityData.community.slice(0, SHOW_MORE_THRESHOLD));
	const institutionData = communityData.community;
	const slicedInstitutionData = institutionData.slice(0, SHOW_MORE_THRESHOLD);
	const singular = communityData.total_placements === 1;
	const subtitle = `At least ${communityData.total_placements} student${
		singular ? ' has' : 's have'
	} found their home here through DigsConnect`;

	useEffect(() => {
		setMapData(showMore ? institutionData : slicedInstitutionData);
	}, [showMore]);
	return (
		<CommunityContainer>
			<SubTitle>{subtitle}</SubTitle>
			<List>
				{mapData.map((institution: any) => (
					<ListItem>
						<ItemUnit>
							<ItemNumber>{institution.placements} </ItemNumber>
							<ItemText>
								{institution.name} student{institution.placements > 1 && 's'}
							</ItemText>
						</ItemUnit>
					</ListItem>
				))}
			</List>
			{institutionData.length > SHOW_MORE_THRESHOLD && (
				<Button isOutline noMargin onClick={() => setShowMore(prev => !prev)}>
					{showMore ? 'Hide' : `Show all ${institutionData.length}`}
				</Button>
			)}
		</CommunityContainer>
	);
};

export default CommunitySection;

const CommunityContainer = styled.div`
	display flex;
	flex-direction: column;
`;

const SubTitle = styled.div`
	font-size: 20px;
	font-weight: ${fontWeights.bold};
	margin-bottom: 24px;
`;

const ItemUnit = styled.div`
	font-size: 16px;
	font-weight: ${fontWeights.normal};
	display: flex;
	flex-direction: row;
	gap: 8px;
	margin-bottom: 8px;
	min-height: 24px;
`;

const ItemNumber = styled.div`
	font-weight: ${fontWeights.bold};
`;
const ItemText = styled.div`
	font-weight: ${fontWeights.normal};
	color: ${colors.grey60};
`;

const ListItem = styled.li`
	list-style: unset;
	margin-bottom: 8px;
`;

const List = styled.ul`
	list-style-position: inside;
	padding-left: 0px;
`;
