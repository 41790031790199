import styled from 'styled-components';
import theme from 'config/theme';

const { colors } = theme;

const StyledHorizontalDivider = styled.div`
	border-top: 1px solid ${colors.divider};
	margin-top: 0;
	margin-bottom: 24px;
`;

export default StyledHorizontalDivider;
