import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { ReactComponent as TimerIcon } from 'assets/images/digs-listing/timer.svg';
import { ReactComponent as MessageSquareIcon } from 'assets/images/digs-listing/message_square.svg';
import { ReactComponent as PersonCheckedIcon } from 'assets/images/digs-listing/person_checked.svg';

const { colors } = theme;

interface LandlordBadgesProps {
	replyRatePercentage: number;
	responseTimeHours: number;
	isTrustedLandlord?: boolean;
	marginBottom?: number;
}

export const LandlordBadges = ({
	replyRatePercentage,
	responseTimeHours,
	isTrustedLandlord,
	marginBottom = 24,
}: LandlordBadgesProps) => {
	return (
		<>
			{(!!isTrustedLandlord || !!replyRatePercentage || !!responseTimeHours) && (
				<LandlordStatsContainer marginBottom={marginBottom}>
					{!!(isTrustedLandlord) && (
						<LandlordStat>
							<IconContainer>
								<PersonCheckedIcon width={32} />
							</IconContainer>
							<StatText>Trusted landlord</StatText>
						</LandlordStat>
					)}
					{!!replyRatePercentage && (
						<LandlordStat>
							<IconContainer>
								<MessageSquareIcon width={32} />
							</IconContainer>
							<StatText>{replyRatePercentage}% reply rate</StatText>
						</LandlordStat>
					)}
					{!!responseTimeHours && (
						<LandlordStat>
							<IconContainer>
								<TimerIcon fill={colors.pink} width={32} />
							</IconContainer>
							<StatText>Replies in {responseTimeHours} hours</StatText>
						</LandlordStat>
					)}
				</LandlordStatsContainer>
			)}
		</>
	);
};

const LandlordStatsContainer = styled.div<{ marginBottom?: number }>`
	display: flex;
	margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const LandlordStat = styled.div`
	margin-right: 16px;
	display: flex;
	align-items: center;
`;

const IconContainer = styled.div`
	margin-right: 4px;
`;

const StatText = styled.div`
	color: ${colors.pink};
	font-weight: 500;
	font-size: 12px;
`;
