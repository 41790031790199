import React, { useState } from 'react';

import { ReactComponent as DrivingIcon } from 'assets/images/digs-listing/driving.svg';
import { ReactComponent as WalkingIcon } from 'assets/images/digs-listing/walking.svg';
import styled from 'styled-components';
import { Select } from 'lib/Select';
import theme from 'config/theme';

const { colors } = theme;

const TravelType = {
	Driving: 'driving',
	Walking: 'walking',
};

const getUniDisplayName = uni => `${uni.name} ${uni.campus ? `(${uni.campus})` : ''}`;

const TravelTimes = ({ universityDistances }) => {
	const [selectedUniversity, setSelectedUniversity] = useState(universityDistances[0]);

	const onUniversitySelectionChange = option => {
		setSelectedUniversity(universityDistances.find(uni => getUniDisplayName(uni) === option.label));
	};

	const options = universityDistances.map(uni => ({
		label: getUniDisplayName(uni),
		value: getUniDisplayName(uni),
	}));

	const getTravelIcon = type => {
		if (type === TravelType.Driving) {
			return <DrivingIcon style={{ width: 14 }} />;
		}
		if (type === TravelType.Walking) {
			return <WalkingIcon style={{ width: 14 }} />;
		}
		return;
	};

	return (
		<TravelTimesContainer>
			<Select
				isOutline
				placeholder={'Select institution'}
				options={options}
				selectedOption={options.find(
					option => !!selectedUniversity && option.value === getUniDisplayName(selectedUniversity),
				)}
				onChange={onUniversitySelectionChange}
			/>
			{selectedUniversity &&
				selectedUniversity.distances.map(distance => {
					const icon = getTravelIcon(distance.type);
					return (
						!!icon && (
							<DistanceItem>
								<IconContainer> {icon} </IconContainer>
								<TravelTime>{distance.time}</TravelTime>
							</DistanceItem>
						)
					);
				})}
		</TravelTimesContainer>
	);
};

export default TravelTimes;

const TravelTimesContainer = styled.div`
	margin-top: 24px;
`;

const DistanceItem = styled.div`
	display: flex;
	align-items: center;
`;

const TravelTime = styled.div`
	color: ${colors.grey60};
	font-size: 14px;
`;

const IconContainer = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
