import React, { Component } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { ListingFeatureCategory } from 'models/listing';

import FeatureSection from './FeatureSection';
import ListingFeaturesDetailsModal from './ListingFeaturesDetailsModal';
import { FeatureCategory } from 'models/property';
import unsupportedFunctionReplacements from 'utils/unsupportedFunctionReplacements';

const getCategoryDisplayText = categoryName => {
	if (categoryName === FeatureCategory.Furniture) {
		return 'Furniture & appliances included';
	}
	if (categoryName === FeatureCategory.LeaseConditions) {
		return 'Additional costs';
	}
	if (categoryName === ListingFeatureCategory.Furniture) {
		return 'Furniture included';
	}
	if (categoryName === ListingFeatureCategory.OfferedFeatures) {
		return 'This place offers';
	}
	return categoryName;
};

class ListingFeatures extends Component {
	static propTypes = {
		features: PropTypes.object,
	};

	static defaultProps = {
		features: {},
		singleCategoryDisplay: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			showDetailedFeatures: false,
			activeFeatureKey: null,
		};
	}

	onShowFeatureDetails = activeFeatureKey => {
		this.setState(state => ({
			showDetailedFeatures: !state.showDetailedFeatures,
			activeFeatureKey: activeFeatureKey,
		}));
	};

	onHideFeatureDetails = () => {
		this.setState({ showDetailedFeatures: false, activeFeatureKey: null });
	};

	render() {
		const { showDetailedFeatures, activeFeatureKey } = this.state;
		const {
			features,
			categoryName,
			includedCategories,
			customTitle,
			hasDivider,
			hasTopDivider,
			currency,
		} = this.props;

		//ensure rules are last
		const featureWithoutExclusions = _.omit(features, [
			FeatureCategory.Rules,
			ListingFeatureCategory.OfferedFeatures,
		]);
		const rulesFeature = features[FeatureCategory.Rules];

		let featureEntries = Object.entries(featureWithoutExclusions);
		if (rulesFeature != null) {
			featureEntries = [...featureEntries, [FeatureCategory.Rules, rulesFeature]];
		}

		if (categoryName) {
			const featureItems = includedCategories
				? unsupportedFunctionReplacements
						.flatten(includedCategories.map(category => features[category]))
						.filter(Boolean)
				: features[categoryName];
			const displayText = customTitle || getCategoryDisplayText(categoryName);

			if (featureItems && featureItems.length < 1) {
				return null;
			}

			return (
				<FeatureSection
					items={featureItems}
					title={displayText}
					hasDivider={!hasTopDivider && hasDivider}
					hasTopDivider={hasTopDivider}
					showMore={showDetailedFeatures}
					currency={currency}
					onShowMoreButtonClick={() => this.onShowFeatureDetails(categoryName)}
				/>
			);
		}

		return (
			<>
				{featureEntries
					.filter(([_, items]) => {
						return Boolean(items.length);
					})
					.map(([title, items]) => {
						return (
							<FeatureSection
								currency={currency}
								key={title}
								items={items}
								title={title}
								hasDivider={hasDivider}
								onShowMoreButtonClick={() => this.onShowFeatureDetails(title)}
							/>
						);
					})}
				{!!showDetailedFeatures && (
					<ListingFeaturesDetailsModal
						items={features[activeFeatureKey]}
						title={activeFeatureKey}
						onDismiss={this.onHideFeatureDetails}
					/>
				)}
			</>
		);
	}
}
export default ListingFeatures;
