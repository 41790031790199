import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { BREAKPOINT_TABLET_LARGE_PX } from 'constants/breakPoints';
import { RoomCarousel } from 'components/RoomCarousel';
import { Currency, Listing } from 'models/listing';
import { Room } from 'models/property';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { Button } from 'lib/Button';
import { ReactComponent as BedroomIcon } from 'assets/images/icons/bedroom.svg';
import { ReactComponent as BathroomIcon } from 'assets/images/icons/bathroom.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/digs-listing/people.svg';
import { imgixFallback } from 'config/imgix';
import urlUtils from 'utils/urlUtils';

const { colors } = theme;

const defaultMaxHorizontalCards = 2;

const addImgixParams = (url: string): string =>
	urlUtils.appendQueryParams(url, `auto=compress&auto=format&fit=crop&w=300&h=160`);

interface BedroomCardsProps {
	rooms: any[];
	currency: Currency;
	listing: Listing;
	maxHorizontalCards?: number;
	index?: number;
	onRoomClick?(room: Room): void;
}

const Card = ({ room, onClick }: any) => (
	<CardContainer>
		{Boolean(room.photos.length) && (
			<RoomImage
				src={room.photos[0] ? addImgixParams(room.photos[0].url) : addImgixParams(imgixFallback)}
			/>
		)}
		<TitleRow>
			<CardTitleV2> {room.title} </CardTitleV2>
			{Boolean(room.guests) && (
				<OccupantsDisplay>
					<span>{room.guests}</span>
					<PeopleIcon style={{ marginLeft: 8 }} />
				</OccupantsDisplay>
			)}
		</TitleRow>
		<Item>
			<BedroomIcon style={{ marginRight: 8 }} />
			<IconText>{room.bedroom_type} room</IconText>
		</Item>
		<Item>
			<BathroomIcon style={{ marginRight: 8 }} />
			<IconText>{room.bathroom_type} bathroom</IconText>
		</Item>
		<Button isOutline height={32} onClick={onClick}>
			Show room details
		</Button>
	</CardContainer>
);

export const BedroomCards = ({
	rooms,
	currency,
	listing,
	maxHorizontalCards,
	index,
	onRoomClick,
}: BedroomCardsProps) => (
	<RoomCarousel
		hideSelectButton
		listing={listing}
		rooms={rooms}
		maxHorizontalCards={maxHorizontalCards || defaultMaxHorizontalCards}
		CardComponent={Card}
		currency={currency}
		index={index}
		onRoomClick={onRoomClick}
	/>
);

const cardHorizontalMargin = 8;

const CardContainer = styled.div`
	border-radius: 16px;
	border: 1px solid ${colors.grey10};
	padding: 16px;
	width: 50%;
	max-width: 320px;
	min-width: 250px;
	margin: 0 ${cardHorizontalMargin}px;
	flex: 1;

	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX + 1}px) {
		width: calc(100% / ${defaultMaxHorizontalCards});
		max-width: calc(100% / ${defaultMaxHorizontalCards});
		min-width: 250px;
	}
`;

const CardTitle = styled.div`
	font-weight: 500;
	margin-bottom: 8px;
`;

const CardTitleV2 = styled.div`
	font-weight: 600;
	height: 32px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const CardDetail = styled.div`
	font-size: 14px;
	line-height: 16px;
	color: ${colors.grey60};
`;

const Item = styled.div`
	display: flex;
	margin-bottom: 12px;
	align-items: center;
`;

const IconText = styled.div`
	color: ${colors.grey60};
`;

const RoomImage = styled.img`
	border-radius: 8px;
	height: 160px;
	width: 100%;
	object-fit: cover;
	margin-bottom: 12px;
`;

const TitleRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 16px;
`;

const OccupantsDisplay = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 14px;
	font-weight: 600;
	margin-left: 16px;
`;
