import _ from 'lodash';

export function universityDistanceText(university_distances) {
	if (!university_distances || !university_distances.length) {
		return '';
	}

	const minKmPerUniversity = university_distances.map(university => {
		const { name, distances } = university;

		const kmArray = distances.map(({ distance }) =>
			typeof distance === 'string' ? +distance.split(' ')[0] : distance,
		);
		const minKm = Math.min(...kmArray);

		return { name, km: minKm };
	});

	const closestUniversity = _.minBy(minKmPerUniversity, 'km');
	const { km, name } = closestUniversity;

	return `${km}km away from ${name}`;
}
