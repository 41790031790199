export enum InfoTooltipType {
	None,
	ReplyRate,
	ResponseTime,
	TrustedLandlord,
}

export const getLandlordDetailsTooltipText = (type: InfoTooltipType): string => {
	if (type === InfoTooltipType.ReplyRate)
		return 'Reply rate is the amount of enquries this landlord responds to in relation to all received enquries';
	if (type === InfoTooltipType.ResponseTime)
		return 'This is the average amount of time it takes landlords to reply to enquiries.';
	if (type === InfoTooltipType.TrustedLandlord)
		return 'This landlord has been verified by DigsConnect and can be trusted. They have placed tenants on DigsConnect and verified themselves.';
	return '';
};
