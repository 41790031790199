import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import _ from 'lodash';
import { ListingFeatureCategory } from 'models/listing';
import { FeatureCategory } from 'models/property';
import unsupportedFunctionReplacements from 'utils/unsupportedFunctionReplacements';

export function getUniversityDropdownOptions(institutions) {
	return institutions.map(university => {
		const campus = university.campuses[0];

		return {
			value: university.id,
			label: university.name,
			lat: campus.latitude,
			lng: campus.longitude,
		};
	});
}

export function getDigsStateData(digs) {
	return {
		...digs,

		listingId: digs.uuid,
		landlord: landlordTransformer(digs.landlord),
		features: featuresTransformer(digs.features),
		listingFeatures: digs.features,
		is_available: isAvailableNow(digs.availability_date),
		is_single_lease: digs.lease_type === 'single',
		rooms: roomsTransformer(digs.rooms),
		security_deposit: getSecurityDeposit(digs.features),
		admin_fee: getLandlordAdminFee(digs.features),
		roomsList: digs.rooms,
	};
}

function landlordTransformer(landlord) {
	const {
		uuid,
		biography,
		first_name,
		profile_picture,
		cellphone_verified,
		email_verified,
		id_verified,
		social_verified,
		join_date,
		is_trusted_landlord,
	} = landlord;

	const verifications = transformVerifications(
		cellphone_verified,
		email_verified,
		id_verified,
		social_verified,
	);

	return {
		id: uuid,
		biography,
		first_name,
		profile_picture: profile_picture && profile_picture.url,
		verifications: verifications,
		join_date: join_date,
		is_trusted_landlord: is_trusted_landlord,
	};

	function transformVerifications(
		cellphone_verified,
		email_verified,
		id_verified,
		social_verified,
	) {
		const verifications = [];

		if (email_verified) verifications.push('Email');
		if (id_verified) verifications.push('ID');
		if (social_verified) verifications.push('Social Network');
		if (cellphone_verified) verifications.push('Cellphone');

		return verifications;
	}
}

function getSecurityDeposit(features) {
	const securityDeposit = _.find(features, { name: 'Security Deposit' });

	if (!securityDeposit) return null;

	return securityDeposit.meta.number;
}

function getLandlordAdminFee(features) {
	const landlordAdminFee = _.find(features, { name: 'Landlord Admin Fee' });

	if (!landlordAdminFee) return null;

	return landlordAdminFee.meta.number;
}

function featuresTransformer(features) {
	const groupedFeatures = _(features)
		.filter(feature => feature.category !== 'Lease Conditions')
		.map(feature => {
			return {
				...feature,
				category: categoryTransformer(feature.category),
			};
		})
		.groupBy(feature => feature.category)
		.value();

	const originalGroupedFeatures = _(features)
		.groupBy(feature => feature.category)
		.value();
	const offeredFeatures = unsupportedFunctionReplacements.flatten(
		Object.entries(groupedFeatures)
			.filter(
				([title]) =>
					title !== FeatureCategory.Furniture &&
					title !== FeatureCategory.Rules &&
					title !== FeatureCategory.LeaseConditions,
			)
			.map(([_, items]) => items),
	);

	return {
		...groupedFeatures,
		...originalGroupedFeatures,
		[ListingFeatureCategory.OfferedFeatures]: offeredFeatures,
	};

	function categoryTransformer(category) {
		switch (category) {
			case 'Bills':
				return 'Bills included';
			case 'Safety and Security':
				return 'Safety features';
			default:
				return category;
		}
	}
}

function roomsTransformer(rooms) {
	return _(rooms)
		.map(room => ({
			...room,
			features: featuresTransformer(room.features),
			is_available: isAvailableNow(room.availability_date),
		}))
		.mapKeys('uuid')
		.value();
}

const isAvailableNow = availableDate => new Date(availableDate) < new Date();
