import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'config/theme';

const { colors } = theme;

class HouseZoneFeatureCard extends Component {
	static propTypes = {
		icons: PropTypes.array,
		title: PropTypes.string,
		subTitle: PropTypes.string,
	};

	render() {
		const { icons, title, subTitle } = this.props;

		return (
			<StyledHouseZoneFeatureCard>
				<IconsWrapper>
					{icons.map((icon, index) => {
						return <Icon key={index} src={icon} />;
					})}
				</IconsWrapper>
				<RoomText>{title}</RoomText>
				{/* TODOS */}
				{/* <RoomDescription>
          {furnished ? 'Furnished' : 'Not furnished'}
        </RoomDescription> */}
				<RoomDescription>{subTitle}</RoomDescription>
			</StyledHouseZoneFeatureCard>
		);
	}
}

export default HouseZoneFeatureCard;

/**styled components */
const StyledHouseZoneFeatureCard = styled.div`
	border: 1px solid rgba(4, 31, 71, 0.08);
	background-color: #ffffff;
	padding: 12px;
	border-radius: 5px;
	margin-right: 15px;
	margin-bottom: 12px;
	width: 120px;
	min-height: 100px;
`;

const Icon = styled.img`
	height: 16px;
	margin-right: 5px;
	margin-bottom: 5px;
`;

const IconsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: start;
`;

const RoomText = styled.p`
	font-size: 14px;
	color: #041f47;
	font-weight: 600;
	text-transform: capitalize;
`;

const RoomDescription = styled.p`
	font-size: 14px;
	font-weight: 400;
	color: ${colors.grey60};
	text-transform: capitalize;
`;
