export function getBathroomZoneFeatureCard(room) {
	const { features, bathroom_type, uuid } = room;

	const bathroomFeatures = features['Bathroom'] != null ? features['Bathroom'] : [];

	const bathroomIcons = bathroomFeatures.map(feature => feature.url);

	const title = `${bathroom_type}`;

	return {
		id: uuid,
		title: title,
		subTitle: '',
		icons: [...bathroomIcons],
	};
}

export function getBedroomZoneFeatureCard(room) {
	const { features, bedroom_type, uuid } = room;
	const bedRoomFeatures = features['Beds'] != null ? features['Beds'] : [];

	const bedIcons = bedRoomFeatures.map(feature => feature.url);

	const title = `${bedroom_type} room`;

	return {
		id: uuid,
		title: title,
		subTitle: '',
		icons: [...bedIcons],
	};
}

export function getSingleListingZoneFeatureCard(room) {
	const { features, bathroom_type, uuid, title } = room;

	const bathroomFeatures = features['Bathroom'] != null ? features['Bathroom'] : [];
	const bedRoomFeatures = features['Beds'] != null ? features['Beds'] : [];

	const bathroomIcons = bathroomFeatures.map(feature => feature.url);
	const bedIcons = bedRoomFeatures.map(feature => feature.url);

	return {
		id: uuid,
		title: title ? title : 'Room',
		subTitle: `${bathroom_type} bathroom`,
		icons: [...bathroomIcons, ...bedIcons],
	};
}
