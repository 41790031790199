import React from 'react';

import styled from 'styled-components';
import CircleCheckIcon from 'assets/images/listing/circle-check-icon.svg';

function getTrustCheckItem(text) {
	return (
		<TrustCheckItem key={text}>
			<TrustCheckIcon src={CircleCheckIcon} />
			{text}
		</TrustCheckItem>
	);
}

function TrustChecks({ verifications = [] }) {
	return (
		<TrustCheckWrapper>
			{verifications.map(verification => getTrustCheckItem(verification))}
		</TrustCheckWrapper>
	);
}

export default TrustChecks;

/**styledd components */
const TrustCheckWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 14px;
`;

const TrustCheckItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	margin-bottom: 16px;
	margin-right: 16px;

	font-weight: 600;
	color: #ff0452;
	font-size: 12px;
`;

const TrustCheckIcon = styled.img`
	height: 24px;
	margin-right: 8px;
`;
