import _ from 'lodash';
import queryString from 'query-string';

const MAX_OVERVIEW_IMAGES = 5;

export function getCarouselImages(photos) {
	const imgixParams = {
		'max-w': '490',
		h: '250',
		fit: 'crop',
		auto: ['format', 'compress'],
	};

	return getFeaturePaddedImages(photos).map(photoUrl => {
		return addImgixParams(photoUrl, imgixParams);
	});
}

export function getDesktopOverviewImages(photos) {
	const imgixParams = {
		h: 400,
		fit: 'crop',
		auto: ['format', 'compress'],
	};

	return getFeaturePaddedImages(photos).map(photoUrl => {
		return addImgixParams(photoUrl, imgixParams);
	});
}

function getFeaturedImages(photos) {
	//get a spread of images from each tag
	const groupedPhotos = _.groupBy(photos, 'tag');

	return Object.values(groupedPhotos)
		.map(photoArray => photoArray[0].url)
		.slice(0, MAX_OVERVIEW_IMAGES);
}

function addImgixParams(url, imgixParams) {
	return queryString.stringifyUrl({ url, query: imgixParams });
}

function getFeaturePaddedImages(photos) {
	const featuredImages = getFeaturedImages(photos);
	const featuredImagesSet = new Set(featuredImages);
	const urls = photos.map(photo => photo.url);

	urls.forEach(url => {
		if (featuredImagesSet.size >= MAX_OVERVIEW_IMAGES || featuredImagesSet.has(url)) {
			return;
		}

		featuredImagesSet.add(url);
	});

	return Array.from(featuredImagesSet);
}
