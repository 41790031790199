import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { coverImage } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

import LandlordDetailsMobile from './LandlordDetailsMobile';
import LandlordDetailsDesktop from './LandlordDetailsDesktop';
import TrustChecks from './TrustChecks';
import { Breakpoint } from '@tiaanduplessis/react-resize';
import { SubTitle } from '../SubTitle';
import { LandlordBadges } from '../LandlordBadges';

const { colors } = theme;

class LandlordSection extends Component {
	static propTypes = {
		biography: PropTypes.string.isRequired,
		first_name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		profile_picture: PropTypes.string.isRequired,
		verifications: PropTypes.array.isRequired,
		listingId: PropTypes.string.isRequired,
		join_date: PropTypes.string.isRequired,
		setModal: PropTypes.object,
	};

	state = { messageSent: false, showDetails: false };

	onDismissDetails = () => {
		this.setState({ showDetails: false });
	};

	renderLandlordDetailsMobile = () => {
		const { showDetails } = this.state;

		const {
			biography,
			first_name,
			profile_picture,
			join_date,
			verifications,
			listingId,
		} = this.props;

		return (
			<LandlordDetailsMobile
				biography={biography}
				first_name={first_name}
				profile_picture={profile_picture}
				show={showDetails}
				onDismiss={this.onDismissDetails}
				verifications={verifications}
				listingId={listingId}
				join_date={join_date}
			/>
		);
	};

	renderLandlordDetailsDesktop = () => {
		const { showDetails } = this.state;
		const {
			biography,
			first_name,
			profile_picture,
			verifications,
			join_date,
			listingId,
		} = this.props;

		return (
			<LandlordDetailsDesktop
				biography={biography}
				first_name={first_name}
				join_date={join_date}
				profile_picture={profile_picture}
				show={showDetails}
				onDismiss={this.onDismissDetails}
				verifications={verifications}
				listingId={listingId}
			/>
		);
	};

	renderLandlordDetails = () => {
		const mobileBreakPointPx = 1080;
		const desktopBreakPointPx = mobileBreakPointPx + 1;

		return (
			<>
				<Breakpoint maxWidth={mobileBreakPointPx}>{this.renderLandlordDetailsMobile()}</Breakpoint>

				<Breakpoint minWidth={desktopBreakPointPx}>
					{this.renderLandlordDetailsDesktop()}
				</Breakpoint>
			</>
		);
	};

	render() {
		const {
			first_name,
			profile_picture,
			verifications,
			join_date,
			is_trusted_landlord,
			replyRatePercentage,
			responseTimeHours,
			listing,
		} = this.props;

		const yearJoined = join_date.split('-')[0];

		return (
			<>
				<SubTitle>{listing.external ? 'Property Contact' : 'About this landlord'}</SubTitle>
				<ProfileWrapper>
					<Picture src={profile_picture} />
					<NameContainer>
						<NameText>{listing.external ? 'Digs International' : first_name}</NameText>
					</NameContainer>
				</ProfileWrapper>
				<LandlordBadges
					replyRatePercentage={replyRatePercentage}
					responseTimeHours={responseTimeHours}
					isTrustedLandlord={is_trusted_landlord}
				/>
				{!listing.external && <DateText>Joined DigsConnect in {yearJoined}</DateText>}
				<TrustChecks verifications={verifications} />
				{this.renderLandlordDetails()}
			</>
		);
	}
}
export default LandlordSection;

const Picture = styled.div`
	${coverImage}
	width: 48px;
	height: 48px;
	border-radius: 50%;
	margin-right: 16px;
	background-color: ${colors.gray};
`;

const NameContainer = styled.div`
	height: fit-content;
`;

const NameText = styled.p`
	color: ${colors.darkBlue};
	font-size: 16px;
	font-weight: 500;
`;

const ProfileWrapper = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	margin-bottom: 24px;
`;

const DateText = styled.p`
	font-weight: 400;
	font-size: 14px;
	color: ${colors.grey60};
	margin-bottom: 24px;
`;
