import React, { useState } from 'react';

import styled from 'styled-components';

import FullScreenModal from '../../../common/fullscreen-modal';
import { ReactComponent as LeftArrow } from 'assets/images/digs-listing/left-arrow.svg';
import ListingGalleryItem from './ListingGalleryItem';
import listingGalleryModeEnum from './listingGalleryModeEnum';
import { getGalleryImages } from './listingGalleryHelpers';
import ImageCarousel from '../../ImageCarousel';
import theme from 'config/theme';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { Cross } from 'lib/Cross';

const { colors } = theme;

const ListingGallery = ({ images, onDismiss, show, videos }) => {
	const [listingGalleryMode, setListingGalleryMode] = useState(
		listingGalleryModeEnum.VIEW_ALL_PHOTOS,
	);
	const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const screenWidth = useScreenWidth();

	const renderListingGalleryItems = () => {
		const groupedImages = getGalleryImages(images);
		const groupedImageEntries = Object.entries(groupedImages);

		return groupedImageEntries.map((entry, i) => {
			const [title, images] = entry;
			const urls = images.map(image => image.url);
			return (
				<ListingGalleryItem
					key={title}
					title={title}
					images={urls}
					onImageClick={imageUrl => onImageClick(imageUrl)}
				/>
			);
		});
	};

	const onImageClick = imageUrl => {
		setListingGalleryMode(listingGalleryModeEnum.VIEW_INDIVIDUAL_PHOTO);

		const imageIndex = images.findIndex(({ url }) => url.split('?')[0] === imageUrl.split('?')[0]);
		setSelectedPhotoIndex(imageIndex);
	};

	const onRequestClose = () => {
		setSelectedPhotoIndex(0);

		if (listingGalleryMode === listingGalleryModeEnum.VIEW_ALL_PHOTOS) {
			onDismiss();
			return;
		}

		setListingGalleryMode(listingGalleryModeEnum.VIEW_ALL_PHOTOS);
	};

	const onImageView = index => {
		setCurrentImageIndex(index);
	};

	return (
		<FullScreenModal showModal={show} onRequestClose={onRequestClose}>
			<TopBar>
				<TopBarContent>
					{listingGalleryMode === listingGalleryModeEnum.VIEW_ALL_PHOTOS && (
						<DismissButton onClick={onRequestClose} />
					)}
					{listingGalleryMode === listingGalleryModeEnum.VIEW_INDIVIDUAL_PHOTO && (
						<>
							<Cross onClick={onRequestClose} />
							<ImageCountText>
								{currentImageIndex + 1} / {images.length}
							</ImageCountText>
						</>
					)}
					<DismissButton style={{ visibility: 'hidden' }} />
				</TopBarContent>
			</TopBar>
			<ContentWrapper>
				{listingGalleryMode === listingGalleryModeEnum.VIEW_ALL_PHOTOS ? (
					<>
						{renderListingGalleryItems()}
						{
							<ListingGalleryItem
								title={'Video tour'}
								images={[]}
								videos={videos && videos.length && [videos[0].url]}
								onImageClick={() => {}}
							/>
						}
					</>
				) : (
					<SelectedPhotoWrapper>
						<ImageCarousel
							images={images.map(({ url }) => url)}
							startingIndex={selectedPhotoIndex}
							height={'100%'}
							fullHeightImage={false}
							greyBackground={false}
							showDirectionalArrows={screenWidth > BREAKPOINT_MOBILE_PX}
							onImageView={onImageView}
						/>
					</SelectedPhotoWrapper>
				)}
			</ContentWrapper>
		</FullScreenModal>
	);
};

export default ListingGallery;

/**styled components */

const TopBarContent = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ImageCountText = styled.div`
	color: ${colors.grey60};
`;

const DismissButton = styled(LeftArrow)`
	height: 16px;
	cursor: pointer;
`;

const TopBar = styled.div`
	border-bottom: 1px solid ${colors.gray};
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const ContentWrapper = styled.div`
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 64px;
	padding-top: 16px;
	height: calc(100% - 64px);
	margin-bottom: 30px;
	margin: auto;
	max-width: 100%;

	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1400px) {
		max-width: 1280px;
	}
`;

const SelectedPhotoWrapper = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;
