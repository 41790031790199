import React from 'react';

import styled from 'styled-components';

import { imgixNsfasLogoUrl } from 'config/imgix';
import { imgixVarsityCollegeLogoUrl } from 'config/imgix';
import { imgixAIELogoUrl } from 'config/imgix';
import theme from 'config/theme';
import routes from 'config/routes';

const { boxShadow, colors, zIndices } = theme;

const VerificationContainer = styled.div<{ count: number }>`
	margin-right: 8px;
	min-width: ${({ count }) => `${(count - 1) * 16 + 36}px`};
	position: absolute;
	z-index: ${zIndices.mapOverlay};
	top: 0;
	right: 0;
  cursor: pointer;
`;

const VerificationWrapper = styled.div`
	width: 16px;
	display: inline-block;
	position: relative;
`;

const Verification = styled.div<{ src?: string; size?: number }>`
  background-image: url("${({ src }) => src}");
  background-size: ${({ size }) => size}px;
  background-position: center;
  width: 36px;
  height: 36px;
  right: 20px;
  bottom: 0px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey10};
  box-shadow: ${boxShadow.small};
  border-radius: 50%;
`;

const handleVerificationsClick = () => {
	window.open(routes.verificationsDisclaimer);
};

interface StackedVerificationsProps {
	nsfas_accredited: boolean;
	varsity_college_accredited: boolean;
	aie_accredited: boolean;
}
const StackedVerifications = ({
	nsfas_accredited,
	varsity_college_accredited,
	aie_accredited,
}: StackedVerificationsProps) =>
	!!nsfas_accredited || !!varsity_college_accredited || !!aie_accredited ? (
		<VerificationContainer
			onClick={handleVerificationsClick}
			count={+(nsfas_accredited || 0) + +(varsity_college_accredited || 0) + +(aie_accredited || 0)}
		>
			{!!aie_accredited && (
				<VerificationWrapper>
					<Verification size={25} src={imgixAIELogoUrl} />
				</VerificationWrapper>
			)}
			{!!varsity_college_accredited && (
				<VerificationWrapper>
					<Verification size={25} src={imgixVarsityCollegeLogoUrl} />
				</VerificationWrapper>
			)}
			{!!nsfas_accredited && (
				<VerificationWrapper>
					<Verification size={27} src={imgixNsfasLogoUrl} />
				</VerificationWrapper>
			)}
		</VerificationContainer>
	) : (
		<></>
	);

export default StackedVerifications;
