import React from 'react';

import styled from 'styled-components';

import { imgixAIELogoUrl, imgixNsfasLogoUrl, imgixVarsityCollegeLogoUrl } from 'config/imgix';
import {
	BREAKPOINT_MOBILE_PX,
} from 'constants/breakPoints';
import { VerificationBadge } from './VerificationBadge';
import routes from 'config/routes';

const VerificationsContainer = styled.div`
	display: flex;
	margin-bottom: 16px;
	align-items: center;
`;

const LabelText = styled.div`
	font-size: 14px;
	font-weight: 600;
	margin-right: 16px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: none;
	}
`;

interface VerificationBadgesProps {
	nsfasAccredited: boolean;
	varsityCollegeAccredited: boolean;
	aieAccredited?: boolean;
  showLabels?: boolean;
}

const handleVerificationsClick = () => {
	window.open(routes.verificationsDisclaimer);
};

export const VerificationBadges = ({
	nsfasAccredited,
	varsityCollegeAccredited,
	aieAccredited,
	showLabels,
}: VerificationBadgesProps) => (
	<VerificationsContainer>
		{!!nsfasAccredited && (
			<>
				<VerificationBadge onClick={handleVerificationsClick} logoUrl={imgixNsfasLogoUrl} />
				{showLabels && <LabelText onClick={handleVerificationsClick}>NSFAS accredited</LabelText>}
			</>
		)}
		{!!varsityCollegeAccredited && (
			<>
				<VerificationBadge
					onClick={handleVerificationsClick}
					logoUrl={imgixVarsityCollegeLogoUrl}
				/>
				{showLabels && <LabelText onClick={handleVerificationsClick}>The IIE's Varsity College verified</LabelText>}
			</>
		)}
		{!!aieAccredited && (
			<>
				<VerificationBadge onClick={handleVerificationsClick} logoUrl={imgixAIELogoUrl} />
				{showLabels && <LabelText onClick={handleVerificationsClick}>AIE verified</LabelText>}
			</>
		)}
	</VerificationsContainer>
);
