import React, { Component } from 'react';

import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Resize from '@tiaanduplessis/react-resize';

import theme from 'config/theme';
import { MAPBOX_ACCESS_TOKEN } from 'config/keys';
import { flexCenter } from 'config/mixins';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import mapImage from 'assets/images/map.png';

import TravelTimes from './TravelTimes';
import { SubTitle } from '../SubTitle';

const { zIndices } = theme;

const { colors, mapBoxTheme } = theme;

const Map = ReactMapboxGl({
	accessToken: MAPBOX_ACCESS_TOKEN,
});

class MapSection extends Component {
	static propTypes = {
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
		universityDistances: PropTypes.array,
	};

	state = {
		showMapCover: true,
	};

	hideMapCover = () => {
		this.setState({ showMapCover: false });
	};

	renderMap = () => {
		const { lat, lng } = this.props;
		const { showMapCover } = this.state;

		const coordinates = { lat, lng };

		return (
			<Resize>
				{({ width }) => {
					const height = '220px';

					const containerStyle = {
						backgroundColor: colors.lightGray,
						height: height,
						width: '100%',
						position: 'absolute',
						top: '0',
						left: '0',
					};

					if (featureFlagContainer.isEnabled(FeatureFlag.Simplify)) {
						return (
							<>
								<MapContainer height={height}>
									{showMapCover ? (
										<MapImage src={mapImage} />
									) : (
										<Map
											style={mapBoxTheme}
											center={coordinates}
											zoom={[13]}
											containerStyle={containerStyle}
										>
											<Marker coordinates={coordinates}>
												<MapMarker />
											</Marker>
										</Map>
									)}
									<MapOverlay onClick={this.hideMapCover} show={showMapCover}>
										<MapText>Click to activate map</MapText>
									</MapOverlay>
								</MapContainer>
							</>
						);
					}

					return (
						<>
							<MapContainer height={height}>
								<Map
									style={mapBoxTheme}
									center={coordinates}
									zoom={[13]}
									containerStyle={containerStyle}
								>
									<Marker coordinates={coordinates}>
										<MapMarker />
									</Marker>
								</Map>
								<MapOverlay onClick={this.hideMapCover} show={showMapCover}>
									<MapOverlayText>Click to activate map</MapOverlayText>
								</MapOverlay>
							</MapContainer>
						</>
					);
				}}
			</Resize>
		);
	};

	render() {
		const { universityDistances, title } = this.props;

		return (
			<div>
				<Container>
					<Column>
						<SubTitle>Location</SubTitle>
						{this.renderMap()}
					</Column>
					{!!universityDistances && !!universityDistances.length && (
						<Column paddingLeft>
							<SubTitle>Transport</SubTitle>
							<TravelTimes universityDistances={universityDistances} />
						</Column>
					)}
				</Container>
			</div>
		);
	}
}

export default MapSection;

/**styled components */

const Container = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: row;
	}
`;

const Column = styled.div`
	flex: 1;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		min-width: 50%;
		${({ paddingLeft }) => (paddingLeft ? 'padding-left: 24px' : '')}
	}
`;

const MapMarker = styled.div`
	border: solid 0.2em ${colors.pink};
	padding: 0.3em;
	width: 2em;
	height: 2em;
	border-radius: 50%;
	${flexCenter}

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: ${colors.pink};
	}
`;

const MapContainer = styled.div`
	width: 100%;
	${({ height }) => height && `height: ${height};`}
	position: relative;
	margin-bottom: 24px;
	border-radius: 16px;
	overflow: hidden;
`;

const MapOverlay = styled.div`
	z-index: ${zIndices.mapOverlay};
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	opacity: 0.9;
	align-items: center;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.4);
	${({ show }) => (show ? 'display: flex;' : `display: none;`)}
	justify-content: center;
	align-items: center;
`;

const MapOverlayText = styled.p`
	font-size: 16px;
	padding-top: 90px;
	color: black;
	text-shadow: white 1px 0 10px;
`;

const MapText = styled.p`
	font-size: 16px;
	color: ${colors.black};
`;

const MapImage = styled.img`
	object-fit: cover;
	width: 100%;
`;
