import React, { Component, createRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import SanitizedHTML from 'react-sanitized-html';

import { universityDistanceText } from './listingDescriptionHelpers';
import { getSingleListingZoneFeatureCard } from '../house-zone-feature-cards/houseZoneFeatureCardHelpers';
import HouseZoneFeatureCard from '../house-zone-feature-cards';
import theme from 'config/theme';
import { Title } from 'lib/Title';
import { Divider } from 'lib/Divider';
import { listingService } from 'services/listingService';
import { numberFormatService } from 'services/numberFormatService';
import { Button } from 'lib/Button';
import { VerificationBadges } from 'components/VerificationBadges';
import {
	BREAKPOINT_MOBILE_PX,
	BREAKPOINT_TABLET_LARGE_PX,
	BREAKPOINT_TABLET_PX,
} from 'constants/breakPoints';
import ArrowButtons from '../ArrowButtons';
import { BedroomCards } from './BedroomCards';
import { SingleRoomCards } from './SingleRoomCards';
import PopularFeaturesBlock from './PopularFeaturesBlock';
import './listing-description.scss';
import { FeatureCategory } from 'models/property';
import LandlordDetails from '../LandlordDetails';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { ReactComponent as BedroomIcon } from 'assets/images/icons/bedroom.svg';
import { ReactComponent as BathroomIcon } from 'assets/images/icons/bathroom.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus.svg';
import { RoomType } from 'models/property';
import { imgixFallback } from 'config/imgix';
import urlUtils from 'utils/urlUtils';

const { colors, boxShadow } = theme;
const TRUNCATED_DESCRIPTION_HEIGHT = 80;
const maxHorizontalRoomCards = 2;
const width =
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const addImgixParams = url =>
	urlUtils.appendQueryParams(url, `auto=compress&auto=format&fit=crop&w=300&h=160`);
const isMobile = width < BREAKPOINT_TABLET_PX;
const showLessCardsLength = isMobile ? 3 : 6;

const isAvailableNow = availableDate => new Date(availableDate) < new Date();

class ListingDescription extends Component {
	static propTypes = {
		title: PropTypes.string,
		location: PropTypes.object,
		university_distances: PropTypes.object,
		feature_highlights: PropTypes.array,
		description: PropTypes.string,
		rooms: PropTypes.object,
		is_single_lease: PropTypes.bool,
		onUniversityDistanceClick: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.descriptionTextRef = createRef();
		this.childrenAnchorRef = createRef();
		this.state = {
			isDescriptionTruncated: true,
			roomIndex: 0,
			showPrivateRooms: props.roomsList.some(
				room => room.bedroom_type === RoomType.Private && (room.active || props.listing.external),
			),
			showSharedRooms: props.roomsList.some(
				room => room.bedroom_type === RoomType.Shared && (room.active || props.listing.external),
			),
			showAllPrivateRooms: false,
			showAllSharedRooms: false,
		};
	}

	componentDidMount() {
		if (
			this.descriptionTextRef.current &&
			this.descriptionTextRef.current.clientHeight <= TRUNCATED_DESCRIPTION_HEIGHT
		) {
			this.setState({ isDescriptionTruncated: false });
		}
	}

	onShowMoreToggle = () => {
		this.setState(state => ({
			isDescriptionTruncated: !state.isDescriptionTruncated,
		}));
	};

	handleArrowClick = arrow => {
		if (arrow === 'left') {
			this.setState(state => ({
				roomIndex:
					state.roomIndex - maxHorizontalRoomCards < 0
						? 0
						: state.roomIndex - maxHorizontalRoomCards,
			}));
		}
		if (arrow === 'right') {
			this.setState(state => ({
				roomIndex:
					state.roomIndex + maxHorizontalRoomCards > this.props.roomsList.length - 1
						? this.props.roomsList.length - 1
						: state.roomIndex + maxHorizontalRoomCards,
			}));
		}
	};

	getAvailablePlacesValue = value => {
		if (value > 100) {
			return '100+';
		}
		if (value > 50) {
			return '50+';
		}
		if (value > 10) {
			return '10+';
		}
		if (!Number(value)) {
			return '';
		}
		return Number(value).toString();
	};

	renderHighlightedFeatures = () => {
		const { feature_highlights } = this.props;

		return feature_highlights.map(perk => {
			return (
				<PerkItem key={perk.name}>
					<PerkIcon src={perk.url} />
					<div>
						<PerkText>{perk.name}</PerkText>
						<PerkSubText>{perk.subtitle}</PerkSubText>
					</div>
				</PerkItem>
			);
		});
	};

	renderSingleLeaseFeatureCards = () => {
		const { rooms, is_single_lease } = this.props;

		if (!is_single_lease) return null;

		const zoneFeatures = Object.values(rooms).map(room => getSingleListingZoneFeatureCard(room));
		return (
			<SingleLeaseFeatureCardWrapper>
				{zoneFeatures.map(feature => {
					return (
						<div key={feature.id}>
							<HouseZoneFeatureCard
								icons={feature.icons}
								title={feature.title}
								subTitle={feature.subTitle}
							/>
						</div>
					);
				})}
			</SingleLeaseFeatureCardWrapper>
		);
	};

	renderShowMoreButton = () => {
		const { isDescriptionTruncated } = this.state;
		const buttonText = isDescriptionTruncated ? 'Read full description' : 'Show less';

		if (
			this.descriptionTextRef.current &&
			this.descriptionTextRef.current.clientHeight <= TRUNCATED_DESCRIPTION_HEIGHT
		) {
			return null;
		}

		return (
			<Button noMargin isOutline onClick={this.onShowMoreToggle}>
				{buttonText}
			</Button>
		);
	};

	renderDescription = () => {
		const { description } = this.props;
		const { isDescriptionTruncated } = this.state;

		return (
			<DescriptionTextContainer isTruncated={isDescriptionTruncated}>
				{isDescriptionTruncated && <TruncatedDescriptionOverlay />}
				<DescriptionText className="listing-description-html" ref={this.descriptionTextRef}>
					<SanitizedHTML html={description} />
				</DescriptionText>
			</DescriptionTextContainer>
		);
	};

	getPaymentPeriodDescription = room => {
		if (room.bedroom_type === RoomType.Shared) {
			return `/ per person`;
		}

		return `/ per ${listingService.getBillingCyclePeriod(this.props.listing)}`;
	};

	renderRoomCards = (rooms, listing) => {
		if (isMobile) {
			return (
				<RoomCardsContainer>
					{rooms.map(room => (
						<RoomCardMobile key={room.uuid}>
							<RoomCardMobileTopSection>
								<RoomCardImageMobileContainer>
									<RoomCardImageMobile
										src={
											room.photos[0]
												? addImgixParams(room.photos[0].url)
												: addImgixParams(imgixFallback)
										}
									/>
									{!room.active && !listing.external && <SoldOutBanner>SOLD OUT</SoldOutBanner>}
								</RoomCardImageMobileContainer>
								<RoomCardContent>
									<RoomCardTitle>{room.title}</RoomCardTitle>
									<Item>
										<BedroomIcon style={{ marginRight: 8 }} />
										<IconText>{room.bedroom_type} room</IconText>
									</Item>
									<Item>
										<BathroomIcon style={{ marginRight: 8 }} />
										<IconText>{room.bathroom_type} bathroom</IconText>
									</Item>
								</RoomCardContent>
							</RoomCardMobileTopSection>
							<RoomCardMobileBottomSection>
								<div>
									{Boolean(room.price) && (
										<Item>
											<PriceText>
												{numberFormatService.formatCurrency(
													room.price,
													listingService.getCurrencySymbol(listing.currency),
												)}
											</PriceText>
											<PaymentPeriod>{this.getPaymentPeriodDescription(room)}</PaymentPeriod>
										</Item>
									)}
									{room.availability_date && (
										<AvailabilityText>
											{isAvailableNow(room.availability_date)
												? 'Available now'
												: `Available from ${new Date(room.availability_date).toDateString()}`}
										</AvailabilityText>
									)}
								</div>
								<RoomCardAction
									greyedOut={!room.active && !listing.external}
									onClick={() => (room.active || listing.external) && this.props.onRoomClick(room)}
								>
									{room.active || listing.external ? 'View' : 'Sold out'}
								</RoomCardAction>
							</RoomCardMobileBottomSection>
						</RoomCardMobile>
					))}
				</RoomCardsContainer>
			);
		}
		return (
			<RoomCardsContainer>
				{rooms.map(room => (
					<RoomCard key={room.uuid}>
						<RoomCardImageContainer>
							<RoomCardImage
								src={
									room.photos[0]
										? addImgixParams(room.photos[0].url)
										: addImgixParams(imgixFallback)
								}
							/>
							{!room.active && !listing.external && <SoldOutBanner>SOLD OUT</SoldOutBanner>}
						</RoomCardImageContainer>
						<RoomCardDetails>
							<RoomCardTitle>{room.title}</RoomCardTitle>
							<Item>
								<BedroomIcon style={{ marginRight: 8 }} />
								<IconText>{room.bedroom_type} room</IconText>
							</Item>
							<Item>
								<BathroomIcon style={{ marginRight: 8 }} />
								<IconText>{room.bathroom_type} bathroom</IconText>
							</Item>
							{Boolean(room.price) && (
								<Item>
									<PriceText>
										{numberFormatService.formatCurrency(
											room.price,
											listingService.getCurrencySymbol(listing.currency),
										)}
									</PriceText>
									<PaymentPeriod>{this.getPaymentPeriodDescription(room)}</PaymentPeriod>
								</Item>
							)}
							{room.availability_date && (
								<Item>
									<AvailabilityText>
										{isAvailableNow(room.availability_date)
											? 'Available now'
											: `Available from ${new Date(room.availability_date).toDateString()}`}
									</AvailabilityText>
								</Item>
							)}
						</RoomCardDetails>
						<RoomCardAction
							greyedOut={!room.active && !listing.external}
							onClick={() => (room.active || listing.external) && this.props.onRoomClick(room)}
						>
							{room.active || listing.external ? 'View details' : 'Sold out'}
						</RoomCardAction>
					</RoomCard>
				))}
			</RoomCardsContainer>
		);
	};

	render() {
		const {
			title,
			university_distances,
			is_single_lease,
			sub_type,
			roomsList,
			total_occupants,
			total_places_available_value,
			currency,
			is_trusted_landlord,
			replyRatePercentage,
			responseTimeHours,
			listing,
			onRoomClick,
			children,
		} = this.props;

		const subType = listingService.getSubTypeText(sub_type).toLowerCase();

		const maxHorizontalCards = this.props.is_single_lease
			? maxHorizontalRoomCards
			: maxHorizontalRoomCards;

		let adjustedRoomIndex = this.state.roomIndex;
		let adjustedMaxHorizonalCards = maxHorizontalCards;

		if (width <= BREAKPOINT_TABLET_LARGE_PX) {
			adjustedRoomIndex = 0;
			adjustedMaxHorizonalCards = roomsList.length;
		}

		const showRightArrow = roomsList.length > adjustedRoomIndex + adjustedMaxHorizonalCards;
		const showLeftArrow = adjustedRoomIndex > 0;

		const tags = (listing.listingFeatures || []).filter(
			feature => feature.category === FeatureCategory.Tags,
		);

		const totalPlacesAvailable = this.getAvailablePlacesValue(total_places_available_value);

		const privateRooms = roomsList.filter(room => room.bedroom_type === RoomType.Private);
		const sharedRooms = roomsList.filter(room => room.bedroom_type === RoomType.Shared);

		const getTagsText = tags => {
			if (tags.length === 1) {
				return tags[0];
			}
			if (tags.length === 2) {
				return tags.join(' & ');
			}
			return `${tags.slice(0, tags.length - 1).join(', ')} & ${tags[tags.length - 1]}`;
		};

		return (
			<div>
				<>
					{(Boolean(listing.nsfas_accredited) ||
						Boolean(listing.varsity_college_accredited) ||
						Boolean(listing.aie_accredited)) && (
						<VerificationBadges
							nsfasAccredited={listing.nsfas_accredited}
							varsityCollegeAccredited={listing.varsity_college_accredited}
							aieAccredited={listing.aie_accredited}
							showLabels
						/>
					)}
					<Title standardMargin>{title}</Title>
					<HighlightText>{listingService.getSubTypeText(listing.sub_type)}</HighlightText>
					{Boolean(tags.length) && <TagsText>{getTagsText(tags.map(tag => tag.name))}</TagsText>}
					<LocationTextContainer
						onClick={() =>
							this.childrenAnchorRef &&
							this.childrenAnchorRef.current &&
							this.childrenAnchorRef.current.scrollIntoView()
						}
					>
						<LocationText>{listingService.getLocationText(listing.location)}</LocationText>
						<LocationText>{universityDistanceText(university_distances)}</LocationText>
					</LocationTextContainer>
					<DesktopOnly>
						<PopularFeaturesBlock listing={listing} />
					</DesktopOnly>
					{this.renderDescription()}
					{this.renderShowMoreButton()}
					<MobileOnly>
						<TopSpacer />
						<PopularFeaturesBlock listing={listing} />
					</MobileOnly>
					<Divider />
					<MobileOnly>
						<LandlordDetails
							listing={listing}
							profilePictureUrl={listing.landlord.profile_picture}
							isLarge
							replyRatePercentage={replyRatePercentage}
							responseTimeHours={responseTimeHours}
							isTrustedLandlord={is_trusted_landlord}
						/>
						<Divider />
					</MobileOnly>
					<div style={{ position: 'relative' }}>
						<div
							ref={this.childrenAnchorRef}
							style={{ visibility: 'hidden', position: 'absolute', top: -96 }}
						></div>
					</div>
					{children}
					<SubTitleText>
						{is_single_lease
							? `Entire ${subType} for rent`
							: `${roomsList.length === 1 ? 'Room' : 'Rooms'} in ${subType} for rent`}
					</SubTitleText>
					<Row>
						<div>
							{!!total_occupants && (
								<CapacityText>Occupant capacity: {total_occupants}</CapacityText>
							)}
							{Boolean(totalPlacesAvailable) && (
								<PinkCapacityText>
									{totalPlacesAvailable} place
									{Number(totalPlacesAvailable) === 1 ? '' : 's'} still available
								</PinkCapacityText>
							)}
						</div>
						{!featureFlagContainer.isEnabled(FeatureFlag.ListingRoomCards) && (
							<ArrowButtons
								showRight={showRightArrow}
								showLeft={showLeftArrow}
								onArrowClick={this.handleArrowClick}
							/>
						)}
					</Row>
					{featureFlagContainer.isEnabled(FeatureFlag.ListingRoomCards) ? (
						<>
							{privateRooms.length > 0 && (
								<>
									<Row>
										<RoomSectionTitle>
											Private {listing.is_single_lease ? 'rooms' : 'room types'} (
											{privateRooms.filter(room => room.active || listing.external).length}{' '}
											available)
										</RoomSectionTitle>
										<div
											onClick={() => {
												this.setState(state => ({
													showPrivateRooms: !state.showPrivateRooms,
												}));
											}}
											style={{ cursor: 'pointer' }}
										>
											{this.state.showPrivateRooms ? <MinusIcon /> : <PlusIcon />}
										</div>
									</Row>
									<Divider />
									{this.state.showPrivateRooms && (
										<>
											{this.renderRoomCards(
												privateRooms.slice(
													0,
													this.state.showAllPrivateRooms
														? privateRooms.length
														: showLessCardsLength,
												),
												listing,
											)}
											{privateRooms.length > showLessCardsLength && (
												<ViewAllButtonContainer>
													<Button
														isOutline
														onClick={() => {
															this.setState(state => ({
																showAllPrivateRooms: !state.showAllPrivateRooms,
															}));
														}}
													>
														{this.state.showAllPrivateRooms ? 'Show less' : 'View all rooms'}
													</Button>
												</ViewAllButtonContainer>
											)}
										</>
									)}
									{sharedRooms.length === 0 && this.state.showPrivateRooms && <Divider />}
								</>
							)}
							{sharedRooms.length > 0 && (
								<>
									<Row>
										<RoomSectionTitle>
											Shared {listing.is_single_lease ? 'rooms' : 'room types'} (
											{sharedRooms.filter(room => room.active || listing.external).length}{' '}
											available)
										</RoomSectionTitle>
										<div
											onClick={() => {
												this.setState(state => ({
													showSharedRooms: !state.showSharedRooms,
												}));
											}}
											style={{ cursor: 'pointer' }}
										>
											{this.state.showSharedRooms ? <MinusIcon /> : <PlusIcon />}
										</div>
									</Row>
									<Divider />
									{this.state.showSharedRooms && (
										<>
											{this.renderRoomCards(
												sharedRooms.slice(
													0,
													this.state.showAllSharedRooms ? sharedRooms.length : showLessCardsLength,
												),
												listing,
											)}
											{sharedRooms.length > showLessCardsLength && (
												<ViewAllButtonContainer>
													<Button
														isOutline
														onClick={() => {
															this.setState(state => ({
																showAllSharedRooms: !state.showAllSharedRooms,
															}));
														}}
													>
														{this.state.showAllSharedRooms ? 'Show less' : 'View all rooms'}
													</Button>
												</ViewAllButtonContainer>
											)}
											<Divider />
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							{is_single_lease ? (
								<BedroomCards
									rooms={roomsList}
									currency={currency}
									listing={listing}
									index={this.state.roomIndex}
									onRoomClick={onRoomClick}
								/>
							) : (
								<SingleRoomCards
									rooms={roomsList}
									currency={currency}
									listing={listing}
									index={this.state.roomIndex}
									onRoomClick={onRoomClick}
								/>
							)}
						</>
					)}
				</>
			</div>
		);
	}
}

export default ListingDescription;

const TagsText = styled.p`
	color: ${colors.grey60};
	margin-bottom: 8px;
`;

const LocationText = styled.p`
	color: ${colors.darkBlue};
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 4px;
	line-height: 16px;
	font-size: 12px;
	cursor: pointer;

	@media (min-width: 768px) {
		font-size: 14px;
	}
`;

const LocationTextContainer = styled.div`
	margin-bottom: 16px;
`;

const PerkItem = styled.div`
	display: flex;
	align-items: start;
	justify-content: start;
	margin-bottom: 16px;

	@media (min-width: 768px) {
		margin-bottom: 20px;
	}
`;

const PerkText = styled.div`
	color: ${colors.darkBlue};
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 8px;

	@media (min-width: 768px) {
		font-size: 16px;
	}
`;

const PerkSubText = styled.div`
	margin-top: 4px;
	font-size: 14px;
	font-weight: 400;
	color: ${colors.grey60};
`;

const PerkIcon = styled.img`
	height: 16px;
	margin-right: 5px;
`;

const SingleLeaseFeatureCardWrapper = styled.div`
	display: flex;
	align-items: left;
	justify-content: left;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 16px;

	@media (max-width: 680px) {
		flex-wrap: nowrap;
		overflow-y: auto;
	}
`;

const DescriptionText = styled.div`
	color: ${colors.darkBlue};
	line-height: 1.7;
	font-size: 14px;
	overflow: auto;
	white-space: pre-line;

	@media (min-width: 768px) {
		font-size: 16px;
	}
`;

const DescriptionTextContainer = styled.div`
	height: auto;
	margin-bottom: 16px;
	${({ isTruncated }) =>
		isTruncated &&
		`
    height: ${TRUNCATED_DESCRIPTION_HEIGHT}px;
    overflow: hidden;
  `};
	position: relative;
`;

const TruncatedDescriptionOverlay = styled.div`
	position: absolute;
	height: 50px;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.4);
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;

const SubTitleText = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 12px;
`;

const CapacityText = styled.div`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 12px;
`;

const PinkCapacityText = styled(CapacityText)`
	color: ${colors.pink};
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
`;

const TopSpacer = styled.div`
	margin-top: 24px;
`;

const MobileOnly = styled.div`
	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX + 1}px) {
		display: none;
	}
`;

const DesktopOnly = styled.div`
	@media (max-width: ${BREAKPOINT_TABLET_LARGE_PX}px) {
		display: none;
	}
`;

const HighlightText = styled.div`
	color: ${colors.pink};
	font-weight: 600;
	margin-bottom: 12px;
	margin-top: -4px;
`;

const RoomCard = styled.div`
	border-radius: 16px;
	background: ${colors.white};
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 180px;
	padding: 16px;
	margin-bottom: 16px;
`;

const RoomCardMobile = styled(RoomCard)`
	display: block;
	height: fit-content;
`;

const RoomCardImage = styled.img`
	width: 100%;
	background: ${colors.grey01};
	border-radius: 16px;
	height: 100%;
	object-fit: cover;
`;

const RoomCardImageContainer = styled.div`
	width: 33%;
	height: 100%;
	position: relative;
`;

const SoldOutBanner = styled.div`
	position: absolute;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 8px;
	top: 8px;
	left: 8px;
	padding: 8px 8px;
	color: ${colors.darkBlue};
	font-size: 14px;
	font-weight: 600;
	white-space: nowrap;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		font-size: 16px;
	}
`;

const RoomCardImageMobile = styled(RoomCardImage)`
	width: 100px;
	height: 100px;
	min-width: 100px;
	min-height: 100px;
	margin-right: 16px;
`;

const RoomCardImageMobileContainer = styled(RoomCardImageContainer)`
	width: 100px;
	height: 100px;
	margin-right: 16px;
`;

const RoomCardMobileTopSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 24px;
`;

const RoomCardMobileBottomSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const RoomCardDetails = styled.div`
	text-align: left;
	height: 100%;
	flex: 1;
	margin-left: 24px;
`;

const RoomCardAction = styled.div`
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	color: ${colors.darkTurquoise};
	padding: 0 16px;
	${({ greyedOut }) =>
		greyedOut
			? `
		cursor: default;
		color: ${colors.grey60};
	`
			: ''}
`;

const RoomCardTitle = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const RoomCardContent = styled.div`
	overflow: hidden;
`;

const RoomSectionTitle = styled.div`
	font-size: 18px;
	font-weight: 600;
	color: ${colors.darkBlue};
`;

const Item = styled.div`
	display: flex;
	margin-bottom: 12px;
	align-items: center;
`;

const IconText = styled.div`
	color: ${colors.darkBlue};
	font-size: 14px;
`;

const PaymentPeriod = styled.span`
	font-weight: 400;
	font-size: 14px;
	color: ${colors.darkBlue};
	margin-left: 4px;
`;

const PriceText = styled.div`
	font-size: 16px;
	font-weight: 600;
	height: 24px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 20px;
`;

const RoomCardsContainer = styled.div`
	margin-bottom: 24px;
`;

const AvailabilityText = styled.div`
	font-size: 12px;
	font-weight: 600;
`;

const ViewAllButtonContainer = styled.div`
	margin-bottom: 24px;
`;
