import React from 'react';

import styled from 'styled-components';

import { Cross } from 'lib/Cross';
import theme from 'config/theme';

const { colors, topBar } = theme;

const Container = styled.div<{ closeOnRight?: boolean }>`
	height: ${topBar.height};
	width: 100%;
	border-bottom: 1px solid ${colors.grey10};
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 24px;
	${({ closeOnRight }) => closeOnRight && 'justify-content: flex-end;'}
`;

interface TopBarProps {
	onClose(): void;
	closeOnRight?: boolean;
}

export const TopBar = ({ closeOnRight, onClose }: TopBarProps) => (
	<Container closeOnRight={closeOnRight}>
		<Cross onClick={onClose} />
	</Container>
);
