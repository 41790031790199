import _ from 'lodash';
import queryString from 'query-string';

export function getGalleryImages(photos) {
	const imgixParams = {
		w: 360,
		h: 270,
		fit: 'crop',
		auto: ['format', 'compress'],
	};

	return _(photos)
		.map(photo => ({
			tag: photo.tag || 'General',
			url: addImgixParams(photo.url, imgixParams),
		}))
		.groupBy('tag')
		.value();
}

function addImgixParams(url, imgixParams) {
	return queryString.stringifyUrl({ url, query: imgixParams });
}
